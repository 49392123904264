/* eslint-disable @typescript-eslint/no-empty-interface */
import { LabelService } from '@services/label';
import { PrintServise } from '@services/print';
import { ReportsService } from '@services/reports';
import StoreService from '@services/store';
import { AxiosResponse } from 'axios';
import { toJS } from 'mobx';
import { applySnapshot, flow, getParent, SnapshotIn, types } from 'mobx-state-tree';
import { deleteFromGlobalBlob, saveGlobalBlob } from 'utils/globalObject';

import { RootStore } from '.';

const { model, maybeNull, string, literal, union, number, array, boolean, optional } = types;

const Printers = model('Printers', { termo: maybeNull(string), others: maybeNull(string) }).actions((self) => ({
	setTermo(value: string) {
		self.termo = value;
	},
	setOthers(value: string) {
		self.others = value;
	},
}));

const PrintTask = model('PdfModalData', {
	type: union(
		literal('label'),
		literal('sales-receipt'),
		literal('return-application'),
		// literal('software-receipt'),
		// literal('acceptance-for-individuals'),
		literal('thermal'),
		// literal('receipt-issuence-np'),
		// literal('receipt-upon-acceptance-for-legal-entities'),
		literal('np-acceptance-receipt'),
		literal('get-group-acceptance-receipt-for-legal-entities'),
		literal('get-group-acceptance-receipt-for-individuals'),
		literal('get-group-issuing-cash-on-delivery'),
		literal('get-group-issuance-receipt-for-individuals'),
		literal('refund-act'),
		literal('payment-act'),
		literal('cash-register-report-pdf'),
		literal('cash-register-report-pdf-employees-general'),
		literal('cash-register-report-pdf-employees-full'),
		literal('autopsy-act'),
		literal('defective-condition-act'),
		literal('claim-act'),
		literal('qr')
	),
	number: maybeNull(string),
	id: maybeNull(number),
	ids: array(number),
	copies: '1',
	date: maybeNull(string),
}).views((self) => ({
	get isMarketLabel() {
		return self.type && ['thermal', 'receipt-issuence-np', 'np-acceptance-receipt'].includes(self.type);
	},
	get taskId() {
		return String(this.isMarketLabel ? self.number : self.id);
	},
}));

const Print = model('Print', {
	printers: Printers,
	tmpPrinters: Printers,
	availablePrinters: array(string),
	printPreview: maybeNull(PrintTask),
	pdfPreviewDataId: maybeNull(string),
	printerSettingsOpen: optional(boolean, false),
	delayedTasks: array(PrintTask),
})
	.actions((self) => ({
		savePrinters() {
			localStorage.setItem('printers', JSON.stringify(toJS(self.printers)));
			self.printerSettingsOpen = false;
			if (self.delayedTasks.length && self.printers.termo && self.printers.others) {
				self.delayedTasks.forEach((task) => this.printTask(task));
				self.delayedTasks.clear();
			}
		},
		setTermo(value: string) {
			self.printers.termo = value;
		},
		setOthers(value: string) {
			self.printers.others = value;
		},
		setPrintPreview: flow(function* (task: PrintTask) {
			self.printPreview = PrintTask.create(task);
			let res = null;

			if (self.printPreview.type === 'get-group-acceptance-receipt-for-individuals') {
				res = yield LabelService.getIndividualsGroupAcceptance([+self.printPreview.taskId]);
			} else if (self.printPreview.type === 'get-group-acceptance-receipt-for-legal-entities') {
				res = yield LabelService.getJuristicGroupAcceptance([+self.printPreview.taskId]);
			} else if (self.printPreview.type === 'get-group-issuing-cash-on-delivery') {
				res = yield LabelService.getGroupCashOnDelivery([+self.printPreview.taskId]);
			} else if (self.printPreview.type === 'get-group-issuance-receipt-for-individuals') {
				res = yield LabelService.getIndividualsGroupIssuance([+self.printPreview.taskId]);
			} else if (self.printPreview.type === 'cash-register-report-pdf') {
				self.printPreview.date && (res = yield ReportsService.getReportOrdersPdf(self.printPreview.date));
			} else if (self.printPreview.type === 'cash-register-report-pdf-employees-general') {
				self.printPreview.date && (res = yield ReportsService.getReportPostalsPdf(self.printPreview.date, 2));
			} else if (self.printPreview.type === 'cash-register-report-pdf-employees-full') {
				self.printPreview.date && (res = yield ReportsService.getReportPostalsPdf(self.printPreview.date, 1));
			} else if (self.printPreview.type === 'claim-act') {
				res = yield LabelService.getClaimAct(+self.printPreview.taskId);
			} else if (self.printPreview.type === 'autopsy-act') {
				res = yield LabelService.getAutopsyAct(+self.printPreview.taskId);
			} else if (self.printPreview.type === 'defective-condition-act') {
				res = yield LabelService.getDefectiveConditionAct(+self.printPreview.taskId);
			} else if (self.printPreview.type === 'qr') {
				res = yield StoreService.getQrCode();
			} else {
				res = yield LabelService[self.printPreview.isMarketLabel ? 'getAddressMarketLabel' : 'getAddressLabel'](
					self.printPreview.taskId,
					task.type
				);
			}
			saveGlobalBlob(self.printPreview.taskId, res?.data);
			self.pdfPreviewDataId = self.printPreview.taskId;
		}),
		cancelPrintPreview() {
			self.pdfPreviewDataId && deleteFromGlobalBlob(self.pdfPreviewDataId);
			self.printPreview = null;
			self.pdfPreviewDataId = null;
		},
		openPrinterSettings: flow(function* () {
			if (!self.printerSettingsOpen) {
				self.printerSettingsOpen = true;
				applySnapshot(self.tmpPrinters, toJS(self.printers));
				try {
					const res = yield PrintServise.getPrinters();
					self.availablePrinters = yield res.json();
				} catch (e) {
					applySnapshot(self.availablePrinters, []);
				}
			}
		}),
		closePrinterSelect() {
			applySnapshot(self.printers, toJS(self.tmpPrinters));
			self.printerSettingsOpen = false;
			self.delayedTasks.clear();
		},

		printTask(printTask: PrintTask, noLoading?: boolean) {
			if (self.printers.termo && self.printers.others) {
				if (!noLoading) getParent<typeof RootStore>(self).setLoading(true);

				setTimeout(() => {
					const task = PrintTask.create(printTask);
					const closeLoader = !noLoading ? getParent<typeof RootStore>(self).setLoading.bind(null, false) : () => null;
					const callback = ({ data }: AxiosResponse): Promise<void> =>
						PrintServise.print(
							data,
							task.type === 'thermal' ? self.printers.termo || '' : self.printers.others || '',
							task.copies
						)
							.then(closeLoader)
							.catch(closeLoader);

					if (task.type === 'get-group-acceptance-receipt-for-legal-entities') {
						LabelService.getJuristicGroupAcceptance(task.ids).then(callback).catch(closeLoader);
					} else if (task.type === 'get-group-acceptance-receipt-for-individuals') {
						LabelService.getIndividualsGroupAcceptance(task.ids).then(callback).catch(closeLoader);
					} else if (task.type === 'get-group-issuing-cash-on-delivery') {
						LabelService.getGroupCashOnDelivery(task.ids).then(callback).catch(closeLoader);
					} else if (task.type === 'get-group-issuance-receipt-for-individuals') {
						LabelService.getIndividualsGroupIssuance(task.ids).then(callback).catch(closeLoader);
					} else if (task.type === 'refund-act') {
						task.id && LabelService.getRefundAct(task.id).then(callback).catch(closeLoader);
					} else if (task.type === 'payment-act') {
						task.id && LabelService.getPaymentAct(task.id).then(callback).catch(closeLoader);
					} else if (task.type === 'cash-register-report-pdf') {
						task.date && ReportsService.getReportOrdersPdf(task.date).then(callback).catch(closeLoader);
					} else if (task.type === 'cash-register-report-pdf-employees-general') {
						task.date && ReportsService.getReportPostalsPdf(task.date, 2).then(callback).catch(closeLoader);
					} else if (task.type === 'cash-register-report-pdf-employees-full') {
						task.date && ReportsService.getReportPostalsPdf(task.date, 1).then(callback).catch(closeLoader);
					} else if (task.type === 'claim-act') {
						task.id && LabelService.getClaimAct(task.id).then(callback).catch(closeLoader);
					} else if (task.type === 'autopsy-act') {
						task.id && LabelService.getAutopsyAct(task.id).then(callback).catch(closeLoader);
					} else if (task.type === 'defective-condition-act') {
						task.id && LabelService.getDefectiveConditionAct(task.id).then(callback).catch(closeLoader);
					} else if (task.type === 'qr') {
						StoreService.getQrCode().then(callback).catch(closeLoader);
					} else {
						LabelService[task.isMarketLabel ? 'getAddressMarketLabel' : 'getAddressLabel'](task.taskId, task.type)
							.then(callback)
							.catch(closeLoader);
					}
					this.cancelPrintPreview();
				}, 100);
			} else {
				getParent<typeof RootStore>(self).setLoading(false);
				self.delayedTasks.push(printTask);
				this.openPrinterSettings();
			}
		},
	}))
	.views((self) => ({
		get printersSelected() {
			return !!self.printers.others && !!self.printers.termo;
		},
		get termo() {
			return self.printers.termo;
		},
		get others() {
			return self.printers.others;
		},
		get openPrinterSelect() {
			return self.printerSettingsOpen;
		},
		get allowSavePrinters() {
			return self.tmpPrinters.termo || self.tmpPrinters.others;
		},
	}));

export default Print;

export interface PrintTask extends SnapshotIn<typeof PrintTask> {}
