import Input from '@components/input';
import Select, { SelectProps } from '@components/select';
import { PostalService } from '@services/postal';
import { InputRef } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';

import styles from './index.module.scss';

type Types = Awaited<ReturnType<typeof PostalService.getPostalComplaintType>>['data'];

const ComplainTypeSelection: FC<SelectProps & { isNew?: boolean }> = ({ isNew = false, ...props }) => {
	const [types, setTypes] = useState<Types>([]);
	const allTypes = useRef<Types>([]);

	const onInput: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
		setTypes(
			allTypes.current.filter(({ postal_complaint_type_description: description }) =>
				description.toLowerCase().includes(value.toLocaleLowerCase())
			)
		);
	};

	const inputRef = useRef<InputRef>(null);

	useEffect(() => {
		(async () => {
			const types = await PostalService.getPostalComplaintType();
			allTypes.current = types.data;
			setTypes(types.data);
		})();
	}, []);

	return (
		<Select
			dropdownRender={(menu) => (
				<>
					<div style={{ padding: 8 }}>
						<Input onChange={onInput} ref={inputRef} />
					</div>
					<div className={styles.options}>{menu}</div>
				</>
			)}
			{...props}
			options={types
				.map(({ postal_complaint_type_id: id, postal_complaint_type_description: label }) => ({
					value: id,
					label,
				}))
				.filter(({ value }) => (value > 3 && value < 11) || (isNew && value === 14))}
			onDropdownVisibleChange={(open) => setTimeout(() => open && inputRef.current && inputRef.current.focus(), 200)}
			className={styles.select}
		/>
	);
};

export default ComplainTypeSelection;
