import Button from '@components/buttons/button';
import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { Layout, message } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC, useEffect } from 'react';

import NoData from '../../../components/no-data';
import AddCashboxReportModal from './components/add-report';
import KassaTable from './components/table';
import styles from './index.module.scss';

const Kassa: FC = () => {
	const store = useStore();
	const { kassa } = store;
	const [messageApi, contextHolder] = message.useMessage({
		top: 64,
		duration: 2,
		prefixCls: 'messages',
	});

	useEffect(() => {
		kassa.loadKassa();
		return () => kassa.unloadKassa();
	}, []);

	useEffect(() => {
		if (kassa.errorMessage) {
			messageApi.open({
				type: 'error',
				content: kassa.errorMessage,
				className: 'message',
				onClose: () => kassa.setErrorMessage(''),
			});
		}
		//eslint-disable-next-line
	}, [kassa.errorMessage]);
	return (
		<>
			<Layout>
				<Header className={styles.menu_header}>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						<div style={{ fontSize: 20, fontWeight: 500 }}>Касса</div>
						<Button
							icon={<Icon imageName="Plus" stroke={colors.black} fontSize={16} />}
							onClick={kassa.openReportModal}
						>
							Добавить кассовый отчет
						</Button>
					</div>
				</Header>
				<Content>
					<OverlayScrollbarsComponent className={styles.scroll} options={{ scrollbars: { autoHide: 'move' } }}>
						{kassa.list && kassa.list.length > 0 ? <KassaTable /> : <NoData />}
					</OverlayScrollbarsComponent>
				</Content>
			</Layout>
			{contextHolder}
			<AddCashboxReportModal open={kassa.reportModal} onCancel={kassa.closeReportModal} />
		</>
	);
};

export default observer(Kassa);
