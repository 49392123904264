import Modal from '@components/modal';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useRef, useState } from 'react';

import PackSelection from '../common/pack-selection';
import PostalProcessForm from '../common/postal-process-form';
import PostalProcessButtons from '../common/postal-process-form/components/buttons';
import styles from './index.module.scss';

const PostalProcessingModal: FC = () => {
	const [mainForm, setMainForm] = useState(true);
	const visit = useCurrentVisit();
	const onClose = (): void => {
		visit?.endProcessPostal();
	};

	const bodyRef = useRef<HTMLDivElement>(null);
	const bodyHeight = useRef<number | 'auto'>('auto');

	const setPackSelection = () => {
		if (bodyRef.current) {
			bodyHeight.current = bodyRef.current.offsetHeight + 47 ?? 'auto';
			setMainForm(false);
		}
	};

	const setMain = () => {
		bodyHeight.current = 'auto';
		setMainForm(true);
	};

	useEffect(() => {
		if (!visit?.processedPostal) setMainForm(true);
	}, [visit?.processedPostal]);

	return (
		<Modal
			title="Приемка ПО"
			footer={mainForm && <PostalProcessButtons />}
			open={!!visit?.processedPostal && !visit.createOrderModel?.clientId}
			width={976}
			onCancel={onClose}
			centered
			className={styles.modal}
		>
			<div
				className={cn(styles.wrap, { [styles.wrap__main]: mainForm })}
				style={{ height: bodyHeight.current }}
				ref={bodyRef}
			>
				{(mainForm && <PostalProcessForm isMarketAddressId={false} addPackClick={setPackSelection} />) || (
					<PackSelection onBackClick={setMain} postal={visit?.processedPostal} />
				)}
			</div>
		</Modal>
	);
};

export default observer(PostalProcessingModal);
