/* eslint-disable @typescript-eslint/no-empty-interface */
import { ItemType } from '@pages/main/compensations/components/list/components/item';
import { CompensationService } from '@services/compensation';
import { flow, getParent, getRoot, SnapshotIn, types } from 'mobx-state-tree';

import { RootStore } from '..';

const { model, number, array, string, boolean, maybeNull, optional } = types;

export const Compensation = model('Compensation', {
	declarer_fio: string,
	direction: maybeNull(number),
	final_sum: number,
	number: string,
	postal_complaint_id: number,
	postal_item_id: number,
	reason: string,
	sum_compensation: maybeNull(string),
	sum_refund: maybeNull(string),
});

export const CompensationsModel = model('Compensations', {
	list: maybeNull(array(Compensation)),
	activeItem: maybeNull(Compensation),
	confirmModal: optional(boolean, false),
	errorMessage: maybeNull(string),
})
	.actions((self) => {
		const root = getRoot<typeof RootStore>(self);
		return {
			loadCompensations: flow(function* () {
				try {
					root.setLoading(true);
					const compensations = yield CompensationService.getCompensations();
					self.list = compensations.data;
				} finally {
					root.setLoading(false);
				}
			}),
			setActiveItem: (id: number | null) => {
				if (id && self.list) {
					const item = self.list.find(({ postal_complaint_id }) => postal_complaint_id === id);
					self.activeItem = item ? { ...item } : null;
				} else {
					self.activeItem = null;
				}
			},
		};
	})
	.actions((self) => ({
		pay: flow(function* () {
			if (!self.activeItem) return;
			const rootStore = getParent<typeof RootStore>(self);
			rootStore.setLoading(true);

			try {
				yield CompensationService.compensationPay(self.activeItem.postal_complaint_id);
				self.confirmModal = false;
				self.activeItem.sum_refund &&
					rootStore.print.printTask({
						type: 'refund-act',
						id: self.activeItem.postal_complaint_id,
					});
				self.activeItem.sum_compensation &&
					rootStore.print.printTask({
						type: 'payment-act',
						id: self.activeItem.postal_complaint_id,
					});
				self.setActiveItem(null);
				self.loadCompensations();
			} catch (err: any) {
				self.errorMessage = err?.message || err?.error;
			} finally {
				rootStore.setLoading(false);
			}
		}),
		openConfirmModal() {
			self.confirmModal = true;
		},
		closeConfirmModal() {
			self.confirmModal = false;
		},
		setErrorMessage: (message: string | null) => {
			self.errorMessage = message;
		},
	}))
	.views((self) => ({
		get tableItems(): ItemType[] | null {
			return (
				self.list?.map(({ number, declarer_fio: fio, final_sum: price, postal_complaint_id: id }) => ({
					number,
					fio,
					price,
					id,
				})) || null
			);
		},
	}));

export interface Compensations extends SnapshotIn<typeof CompensationsModel> {}
