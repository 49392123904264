/* eslint-disable @typescript-eslint/no-empty-interface */
import { PostalService } from '@services/postal';
import { flow, getRoot, Instance, SnapshotIn, types } from 'mobx-state-tree';

import { RootStore } from '..';

const { model, number, string, boolean, union, literal, array, maybeNull, optional } = types;

const Packaging = model('Packaging', {
	packing_item_id: maybeNull(number),
	packing_id: number,
	name: string,
	quantity: number,
	sum: number,
});

const Client = model({
	name: string,
	surname: string,
	patronymic_name: optional(string, ''),
	phone_number: maybeNull(string),
}).views((self) => ({
	get fio() {
		return `${self.surname} ${self.name} ${self.patronymic_name}`;
	},
}));

const Postal = model('Postal', {
	id: number,
	number: string,
	market_status: number,
	market_status_name: string,
	type: union(literal(0), literal(1), literal(2)),
	delivery_type: maybeNull(union(literal(1), literal(2))),
	receiver: Client,
	sender: Client,
	pickup_address: maybeNull(
		array(
			model('Address', {
				Address8Id: string,
				Address7Id: string,
				Address6Id: string,
				Address5Id: string,
				Address4Id: string,
				Address3Id: string,
				Address2Id: string,
				Address1Id: string,
				AddressNameFull: string,
				Latitude: string,
				Longitude: string,
				Address2Id_Status: string,
				Address4Id_Status: string,
				Address5Id_Status: string,
				Porch: string,
				Floorx: string,
				Address8Name: string,
				Address7Name: string,
				Address6Name: string,
				Address5Name: string,
				Address4Name: string,
				Address3Name: string,
				Address2Name: string,
				Address1Name: string,
			})
		)
	),

	stores: optional(
		model('Sttores', {
			start: maybeNull(
				model('StartStore', {
					id: number,
					name: string,
				})
			),
			finish: maybeNull(
				model('FinishStrore', {
					id: maybeNull(number),
					name: maybeNull(string),
				})
			),
		}),
		{}
	),
	weight: model('Weight', {
		value: maybeNull(number),
		type_id: maybeNull(number),
		type_name: maybeNull(string),
	}),
	width: maybeNull(number),
	height: maybeNull(number),
	length: maybeNull(number),
	is_juristic: union(literal(0), literal(1)),
	additional_services: model('Additional_services', {
		is_payment: maybeNull(number),
		payment_amount: maybeNull(string),
		payment_price: maybeNull(string),
		payment_affiliation: maybeNull(union(literal(0), literal(1))),
		is_cash_on: maybeNull(union(literal(0), literal(1))),
		is_fragile: maybeNull(number),
		fragile_price: maybeNull(string),
		is_inventory: maybeNull(number),
		inventory_price: maybeNull(string),
		inventory_amount: maybeNull(string),
		is_completeness: maybeNull(number),
		completeness_price: maybeNull(string),
		is_oversize: maybeNull(number),
		oversize_price: maybeNull(string),
		is_declared: maybeNull(number),
		declared_price: maybeNull(string),
		declared_amount: maybeNull(string),
		is_relabeling: maybeNull(number),
		relabeling_price: maybeNull(string),
	}),
	isCompletenessConfirmed: maybeNull(union(literal(0), literal(1))),
	packaging: array(Packaging),
	tariff_id: maybeNull(number),
	tariff_price: maybeNull(number),
	error: maybeNull(string),
	final_price: maybeNull(number),
	packing_price: maybeNull(number),
	hasUpdate: maybeNull(boolean),
	hasUpdatePacking: maybeNull(boolean),
	acceptLock: false,
})
	.actions((self) => {
		let controller: AbortController | null = null;
		let timeout: any;
		let timeoutPayment: any;

		return {
			setStatus(item: string) {
				self.market_status_name = item;
			},
			setDeliveryType(type: 1 | 2) {
				self.delivery_type = type;
			},
			setReceiverName(name: string) {
				self.receiver.name = name === ' ' ? '' : name;
			},
			setReceiverSurname(name: string) {
				self.receiver.surname = name;
			},
			setReceiverPatronymicName(name: string) {
				self.receiver.patronymic_name = name;
			},
			setReceiverPhone(phone: string) {
				self.receiver.phone_number = phone;
			},
			setWeight(weight: number | null) {
				self.weight.value = weight;
				this.scheduleCalculate();
			},
			setWidth(width: number | null) {
				self.width = width;
				self.hasUpdate = true;
			},
			setHeight(height: number | null) {
				self.height = height;
				self.hasUpdate = true;
			},
			setlength(length: number | null) {
				self.length = length;
				self.hasUpdate = true;
			},
			togglePayment() {
				self.additional_services.is_cash_on = self.additional_services.is_cash_on ? 0 : 1;
				if (!self.additional_services.is_cash_on) {
					self.additional_services.payment_amount = '0';
				}
				this.scheduleCalculate();
			},
			toggleFragile() {
				self.additional_services.is_fragile = Number(!self.additional_services.is_fragile);
				this.scheduleCalculate();
			},
			toggleInventory() {
				self.additional_services.is_inventory = Number(!self.additional_services.is_inventory);
				this.scheduleCalculate();
			},
			toggleCompleteness() {
				self.additional_services.is_completeness = Number(!self.additional_services.is_completeness);
				this.scheduleCalculate();
			},
			toggleOversize() {
				self.additional_services.is_oversize = Number(!self.additional_services.is_oversize);
				this.scheduleCalculate();
			},
			toggleDeclared() {
				self.additional_services.is_declared = Number(!self.additional_services.is_declared);
				if (!self.additional_services.is_declared) {
					self.additional_services.declared_amount = '0';
				}
				this.scheduleCalculate();
			},
			toggleRelabeling() {
				self.additional_services.is_relabeling = Number(!self.additional_services.is_relabeling);
				this.scheduleCalculate();
			},
			setPaymentAmount(value: string) {
				self.additional_services.payment_amount = value;
				this.scheduleCalculate();
			},

			setDeclaredAmount(value: string) {
				self.additional_services.declared_amount = value;
				this.scheduleCalculate();
			},

			setInventoryAmount(value: string) {
				self.additional_services.inventory_amount = value;
				self.hasUpdate = true;
			},
			setPackaging(pack: Packaging) {
				const existed = self.packaging.find(({ packing_id }) => packing_id === pack.packing_id);
				if (existed) self.packaging.remove(existed);
				self.packaging.push(pack);
				self.hasUpdatePacking = true;
				this.scheduleCalculate();
			},
			setPackagingQuantity(id: number, quantity: number) {
				const existed = self.packaging.find(({ packing_id }) => packing_id === id);
				if (existed) {
					if (quantity > 0) existed.quantity = quantity;
					else if (quantity === 0) self.packaging.remove(existed);
				}
				self.hasUpdatePacking = true;
				this.scheduleCalculate();
			},
			setFinishStore(id: number, name: string) {
				self.stores.finish = { id, name };
			},
			confirmCompleteness(value: 0 | 1) {
				self.isCompletenessConfirmed = value;
			},
			setError(error: string | null) {
				self.error = error;
			},
			scheduleCalculate() {
				clearTimeout(timeout);
				timeout = setTimeout(this.setCalculate, 800);
			},
			setCalculate: flow(function* () {
				const root = getRoot<typeof RootStore>(self);
				self.hasUpdate = true;
				const packing = self?.packaging.map(({ packing_id: id, quantity }) => ({ id, quantity })) || [];
				try {
					if (controller) {
						controller.abort();
					}

					controller = new AbortController();

					root.setLoading(true);
					const res = yield PostalService.getPostalCalc(
						self.id,
						{
							is_inventory: self.additional_services.is_inventory,
							is_fragile: self.additional_services.is_fragile,
							is_oversize: self.additional_services.is_oversize,
							is_completeness_check: self.additional_services.is_completeness,
							is_relabeling: self.additional_services.is_relabeling,
							is_cash_on_delivery: self.additional_services.is_cash_on,
							is_cash_on_delivery_declared_value: self.additional_services.is_declared,
							cash_on_delivery_sum: self.additional_services.payment_amount,
							cash_on_delivery_declared_value_sum: self.additional_services.declared_amount || 0,
							postal_weight: self.weight.value,
							packing,
						},
						controller.signal
					);
					controller = null;
					self.error = null;
					self.weight.type_id = res.data.weight?.type_id ?? null;
					self.weight.type_name = res.data.weight?.type_name || null;
					self.tariff_id = res.data.tariff_id ?? null;
					self.tariff_price = res.data.tariff_price ?? null;
					self.final_price = res.data.final_price ?? null;
					self.packing_price = res.data.packing_price ?? null;

					for (const key in res.data.additional_services) {
						if (Object.prototype.hasOwnProperty.call(res.data.additional_services, key)) {
							//@ts-ignore
							self.additional_services[key] = res.data.additional_services[key]
								? '' + res.data.additional_services[key]
								: null;
						}
					}
				} catch (data) {
					//@ts-ignore
					self.error = data?.error || data?.message || null;
				} finally {
					root.setLoading(false);
				}
			}),
			accept: flow(function* (visitNum?: string, isShowlabel?: boolean) {
				const root = getRoot<typeof RootStore>(self);
				const visit = (visitNum && root.visits.visitMap.get(visitNum)) || null;
				if (!visit?.processedPostal || root.loadingForce || root.loading) return;
				visit.processedPostal.setAcceptLock(true);
				root.setLoadingForce(true);
				clearTimeout(timeoutPayment);
				self.error = null;

				const packing = {
					packing:
						self?.packaging.map(({ packing_id: id, quantity, sum }) => ({
							id,
							quantity,
							sum: (quantity * sum).toFixed(2),
						})) || [],
				};
				const postalData = {
					update_or_insert: self.hasUpdate || false,
					height: self.height || 0,
					width: self.width || 0,
					length: self.length || 0,
					inventory_tariff_sum: self.additional_services.inventory_price || 0,
					inventory_stuff_sum: self.additional_services.inventory_amount || 0,
					fragile_tariff_sum: self.additional_services.fragile_price || 0,
					shipment_price_with_tax: self.tariff_price || 0,
					oversize_tariff_sum: self.additional_services.oversize_price || 0,
					completeness_check_price: self.additional_services.completeness_price || 0,
					cash_on_delivery_declared_value_tariff_sum: self.additional_services.declared_price || 0,
					weight_id: self.weight.type_id || 0,
					tariff_id: self.tariff_id || 0,
					is_packing: self.hasUpdatePacking || false,
					is_inventory: self.additional_services.is_inventory,
					is_fragile: self.additional_services.is_fragile,
					is_oversize: self.additional_services.is_oversize,
					is_completeness_check: self.additional_services.is_completeness,
					is_relabeling: self.additional_services.is_relabeling,
					is_cash_on_delivery: self.additional_services.is_cash_on,
					cash_on_delivery_declared_value: self.additional_services.is_declared,
					cash_on_delivery_sum: self.additional_services.payment_amount || 0,
					cash_on_delivery_declared_value_sum: self.additional_services.declared_amount || 0,
					weight: self.weight.value || 0,
					cash_on_delivery_money_back_sum: self.additional_services.payment_price || 0,
				};

				try {
					yield PostalService.postalSave(self.id, postalData);
					if (packing.packing.length) yield PostalService.packing(self.id, packing);

					const { id, number } = visit.processedPostal;

					const promise = new Promise<void>(
						(res, rej) =>
							(timeoutPayment = setTimeout(() => {
								try {
									res();
								} catch {
									rej();
								}
							}, 4000))
					);

					yield promise.then(async () => {
						await PostalService.putPostalPayment(id);
						visit.endProcessPostal(true);
						if (isShowlabel) {
							root.print.printTask({ type: 'thermal', number: visit.getPostal(id)?.number || number || '', id });
						}
						await visit.loadPostals(id, 'postal');
					});
				} catch (err: any) {
					root.setErrorMessage(err?.message || err?.error);
					visit.processedPostal.setAcceptLock(false);
				} finally {
					root.setLoadingForce(false);
				}
			}),
			setAcceptLock(value: boolean) {
				self.acceptLock = value;
			},
		};
	})
	.views((self) => {
		return {
			get message() {
				if (
					self.additional_services.is_cash_on &&
					self.additional_services.is_declared &&
					Number(self.additional_services.payment_amount) > Number(self.additional_services.declared_amount)
				) {
					return 'Сумма наложенного платежа не может быть выше суммы объявленной ценности';
				} else if (self.additional_services.is_fragile && self.additional_services.is_oversize)
					return 'Совместный выбор услуг “Хрупкость” и “Громоздкость” недопустим, выберите одну из услуг';
				else if (self.error) return self.error;
				else return null;
			},
			get isDataValid() {
				return !this.message;
			},
			get packagingSum() {
				return (
					Math.round(self.packaging.reduce((res, item) => res + Number(item.sum) * Number(item.quantity), 0) * 100) /
					100
				);
			},
			getPackaging(id: number) {
				return self.packaging.find(({ packing_id }) => id === packing_id);
			},
			get isRequireCompletenessConfirm() {
				return self.additional_services.is_completeness && self.isCompletenessConfirmed === null;
			},
		};
	});

export const PostalCalculate = model('PostalCalculate', {
	weight: model('Weight', {
		id: maybeNull(number),
		name: maybeNull(string),
	}),
	additional_services: model('Additional_services', {
		payment_price: maybeNull(string),
		fragile_price: maybeNull(string),
		inventory_price: maybeNull(string),
		completeness_price: maybeNull(string),
		oversize_price: maybeNull(string),
		declared_price: maybeNull(string),
		relabeling_price: maybeNull(string),
	}),
	packaging: array(
		model('Packaging', {
			packing_id: number,
			quantity: number,
			sum: number,
		})
	),
	tariff_price: maybeNull(number),
	final_price: maybeNull(number),
	packing_price: maybeNull(number),
});

export default Postal;

export interface Postal extends Instance<typeof Postal> {}

export interface PostalCalculate extends Instance<typeof PostalCalculate> {}

export interface Packaging extends SnapshotIn<typeof Packaging> {}
