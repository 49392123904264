import Button from '@components/buttons/button';
import IconButton from '@components/buttons/icon_btn';
import ContextMenu from '@components/context-menu';
import Modal from '@components/modal';
import NoData from '@components/no-data';
import colors from '@config/colors';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { IncidentItem } from '@store/visits/incidents';
import { ConfigProvider, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC, useEffect } from 'react';

import { DocumentKeys, documentTypes } from '../postal-table/components/documentTypes';
import styles from './index.module.scss';

interface DataType {
	key: number;
	item: IncidentItem;
}

const IncidentModal: FC = () => {
	const store = useStore();
	const visit = useCurrentVisit();
	const id = visit?.icidents?.id;

	useEffect(() => {
		id && visit?.icidents?.loadIncidents(id);
	}, [id]);

	if (!id) return null;

	const columns: ColumnsType<DataType> = [
		{
			title: 'Статус',
			className: cn(styles.cell),
			dataIndex: 'name',
			width: 130,
			render: (_, { item: { market_status: status } }) => (
				<span>{(status === 0 && 'Создан') || (status === 50 && 'В работе') || (status === 90 && 'Закрыт') || ''}</span>
			),
		},
		{
			title: 'Тип инцидента',
			className: styles.cell,
			dataIndex: 'quantity',
			align: 'right',
			width: 130,
			render: (_, { item: { postal_complaint_type_description: type } }) => <span>{type}</span>,
		},
		{
			title: 'Заявитель',
			className: styles.cell,
			dataIndex: 'price',
			width: 130,
			render: (_, { item: { direction } }) => (
				<span>{direction === 0 ? 'Отправитель' : direction === 1 ? 'Получатель' : ''}</span>
			),
		},
		{
			title: 'Дата и время',
			className: styles.cell,
			dataIndex: 'received_quantity',
			width: 130,
			render: (_, { item: { market_created_at: date } }) => <span>{date}</span>,
		},
		{
			title: 'Место',
			className: styles.cell,
			dataIndex: 'final_price',
			width: 130,
			render: (_, { item: { store } }) => <span>{store}</span>,
		},
		{
			title: 'Создал',
			className: styles.cell,
			dataIndex: 'final_price',
			width: 130,
			render: (_, { item: { operator_name } }) => <span>{operator_name}</span>,
		},
		{
			title: 'Решение',
			className: styles.cell,
			dataIndex: 'final_price',
			width: 130,
			render: (_, { item: { complaint_result_name: name } }) => <span>{name}</span>,
		},
		{
			className: cn(styles.cell, styles.cell__actions),
			width: 64,
			render: (
				_,
				{
					item: {
						direction,
						market_status: status,
						id,
						postal_item_number: number,
						store_id: storeId,
						postal_complaint_type_id: reason,
						postal_weight: weight,
						info,
					},
				}
			) => {
				return (
					<>
						{((direction === null && status < 50) || status < 90) && (
							<IconButton
								imageName="Edit"
								color={colors.black}
								fontSize={20}
								btnStyle={{ padding: '2px 0' }}
								onClick={() =>
									visit.createIncident({
										actType: direction ? direction + 1 : 0,
										reason,
										weight: (weight && Number(weight)) || null,
										comment: info,
										number,
										store: storeId,
										isEdit: true,
										status,
										complaintId: id,
									})
								}
							/>
						)}
						<ContextMenu
							items={(
								[(direction === 0 || direction === 1) && 'claim-act', 'autopsy-act', 'defective-condition-act'].filter(
									(item) => item
								) as DocumentKeys[]
							).map((type) => ({
								text: documentTypes[type],
								onClick: () => store.print.setPrintPreview({ type, number: number || '', id }),
							}))}
							zIndex={1000}
						>
							<IconButton imageName="Sheet" color={colors.black} fontSize={20} btnStyle={{ padding: '2px 0' }} />
						</ContextMenu>
					</>
				);
			},
		},
	];

	const number = visit.getPostal(id)?.number || '';

	return (
		<Modal
			title={`Инцидент на почтовое отправление ${number}`}
			footer={null}
			open={true}
			width={1085}
			centered
			onCancel={visit?.icidents?.close}
		>
			<ConfigProvider
				theme={{
					components: { Table: { borderRadius: 0 } },
				}}
			>
				<Button className={styles.btn} onClick={() => visit.createIncident({ number })}>
					<Icon imageName="Plus" fontSize={16} stroke={colors.black} /> Добавить инцидент
				</Button>
				<OverlayScrollbarsComponent className={styles.scroll} options={{ scrollbars: { autoHide: 'move' } }}>
					{visit?.icidents.list && visit.icidents.list.length > 0 ? (
						<Table
							className={styles.table}
							columns={columns}
							dataSource={visit.icidents.list?.map((item) => ({ key: item.id, item })) || []}
							caption={<p className={styles.table__caption}>{visit?.orderItems?.receiver_fio || ''}</p>}
							pagination={false}
							rowClassName={styles.row}
							onHeaderRow={() => ({ className: styles.row__head })}
							rowSelection={{ selectedRowKeys: visit?.orderItems?.selectedKeys, columnWidth: 0 }}
							locale={{ emptyText: 'Нет данных' }}
						/>
					) : (
						<NoData />
					)}
				</OverlayScrollbarsComponent>
			</ConfigProvider>
		</Modal>
	);
};

export default observer(IncidentModal);
