import Button from '@components/buttons/button';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import styles from './index.module.scss';

const ModalSuccess: FC = () => {
	const { indentification } = useStore();
	const [isShow, setIsShow] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsShow(false);
		}, 2000);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<Modal
			title={
				<div className={styles.title}>
					<Icon imageName="CheckCircle" fontSize={72} color="#00C1A2" />
				</div>
			}
			cancelText="Закрыть"
			open={isShow}
			onCancel={() => indentification.setIsOpenModalSuccess(false)}
			centered
			width={416}
			footer={
				<Button onClick={() => indentification.setIsOpenModalSuccess(false)} className={styles.button}>
					Закрыть
				</Button>
			}
		>
			<p className={styles.text}>Клиент Иванова Татьяна Антоновна успешно идентифицирован</p>
		</Modal>
	);
};

export default observer(ModalSuccess);
