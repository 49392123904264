import { PostalTrackingModel } from '@models/postal-tracking';
import { PostalService } from '@services/postal';
import { ConfigProvider, Table } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';

import styles from './index.module.scss';

interface PostalTrackingProps {
	postalId: number;
}

const PostalTracking: FC<PostalTrackingProps> = ({ postalId }) => {
	const [tracking, setTracking] = useState<PostalTrackingModel[]>([]);

	useEffect(() => {
		if (postalId) {
			PostalService.getPostalTracking(postalId).then((res) => {
				setTracking(res.data);
			});
		}
	}, [postalId]);

	return (
		<ConfigProvider theme={{ token: { padding: 10, paddingContentVerticalLG: 8, controlItemBgHover: '#fff' } }}>
			<Table
				className={styles.table}
				columns={[
					{ title: 'Сотрудник', dataIndex: 'employee_login', width: 160 },
					{
						title: 'Дата и время',
						dataIndex: 'timeX',
						render: (value) => dayjs(value).format('DD.MM.YYYY hh:mm:ss'),
						width: 170,
					},
					{ title: 'Комментарий', dataIndex: 'employee_info' },
					{ title: 'Инфо для клиента', dataIndex: 'client_info' },
				]}
				dataSource={[...tracking].reverse()}
				pagination={false}
				scroll={{ y: 400 }}
				locale={{ emptyText: 'Нет данных' }}
			/>
		</ConfigProvider>
	);
};

export default PostalTracking;
