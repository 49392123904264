import { FC } from 'react';

import ClientSearch from './components/search';
import styles from './index.module.scss';

const IdentificationHeader: FC = () => {
	return (
		<>
			<h1 className={styles.title}>Идентификация</h1>
			<ClientSearch />
		</>
	);
};

export default IdentificationHeader;
