import Button from '@components/buttons/button';
import Modal from '@components/modal';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import Icon from '@images/index';
import { Layout } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import VisitsHeader from './components';
import CreateIncident from './components/visit/compoments/incident-create-modal';
import CreateOrder from './components/visit/compoments/order-modal';
import PdfModal from './components/visit/compoments/pdf-modal';
import styles from './index.module.scss';

const Visits: FC = () => {
	const visit = useCurrentVisit();

	return (
		<Layout>
			<Header className={styles.menu_header}>
				<VisitsHeader />
			</Header>
			<Content style={{ padding: 24 }}>
				<Outlet />
				<CreateOrder />
				<CreateIncident />
				<PdfModal />
				<Modal
					title={
						<div className={styles.modal__title}>
							<Icon imageName="CheckCircle" fontSize={72} color="#00C1A2" />
							<span>{visit?.confirmModal}</span>
						</div>
					}
					cancelText="Закрыть"
					open={!!visit?.confirmModal}
					onCancel={() => visit?.setConfirmModal(null)}
					hasLines={false}
					centered
					width={416}
					footer={
						<Button className={styles.modal__button} onClick={() => visit?.setConfirmModal(null)}>
							Закрыть
						</Button>
					}
					style={{ zIndex: 99999 }}
					// afterOpenChange={() => setTimeout(() => visit?.setConfirmModal(null), 2000)}
				/>
			</Content>
		</Layout>
	);
};

export default observer(Visits);
