import Input from '@components/input';
import InputNumber from '@components/inputNumber';
import colors from '@config/colors';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import Icon from '@images/index';
import { Postal } from '@store/visits/postal';
import { Divider } from 'antd';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC } from 'react';
import { formatPhoneNumber } from 'utils/formatPhone';

import PackTags from '../../postal-modal/components/pack-tags';
import DeliveryTypeSelect from '../delivery-type-select';
import PostOfficeSelection from '../post-office-select';
import CheckParameter from './components/check-parameter';
import Person from './components/person';
import Summary from './components/summary';
import styles from './index.module.scss';
import descr from './service-descriptions.json';

interface MainFormProps {
	addPackClick?: () => void;
	isMarketAddressId?: boolean;
}

const PostalProcessForm: FC<MainFormProps> = ({ isMarketAddressId, addPackClick }) => {
	const visit = useCurrentVisit();
	const postal = visit?.processedPostal as Postal;

	if (!postal) return null;

	return (
		<OverlayScrollbarsComponent className={styles.scroll}>
			<div style={{ display: 'flex', gap: 16, padding: '24px 14px 24px 24px' }}>
				<div style={{ width: 400, display: 'flex', flexDirection: 'column', gap: 24 }}>
					<DeliveryTypeSelect
						value={postal?.delivery_type}
						onChange={(value) => postal.setDeliveryType(value as 1 | 2)}
						disabled
					/>
					<Input placeholder="Отделение отправки" disabled value={postal.stores?.start?.name} />
					{postal?.delivery_type === 1 && (
						<PostOfficeSelection
							value={postal.stores.finish?.id}
							onChange={(val) => postal.setFinishStore(Number(val), '')}
							placeholder="Отделение получения"
							disabled
							isMarketAddressId={isMarketAddressId}
						/>
					)}
					{postal?.delivery_type === 2 && postal?.pickup_address && (
						<>
							<div style={{ fontWeight: 500 }}>Адрес доставки</div>
							<Input value={postal.pickup_address[0].Address5Name} placeholder="Населенный пункт " disabled />
							<Input value={postal.pickup_address[0].Address4Name} placeholder="Улица" disabled />
							<div style={{ display: 'flex', gap: 16 }}>
								<Input value={postal.pickup_address[0].Address3Name} placeholder="Дом" disabled />
								<Input value={postal.pickup_address[0].Address2Name} placeholder="Корпус" disabled />
								<Input value={postal.pickup_address[0].Address1Name} placeholder="Квартира" disabled />
							</div>
						</>
					)}
					<Person
						title="Отправитель"
						name={`${postal.sender.surname} ${postal.sender.name} ${postal.sender.patronymic_name || ''}`}
						phone={formatPhoneNumber(postal.sender.phone_number)}
					/>
					<Person
						title="Получатель"
						name={`${postal.receiver.surname} ${postal.receiver.name} ${postal.receiver.patronymic_name || ''}`}
						phone={postal.receiver.phone_number || ''}
						// editable
						initialData={{
							name: postal.receiver.name,
							patronymic: postal.receiver.patronymic_name,
							surname: postal.receiver.surname,
							phone: postal.receiver.phone_number || '',
						}}
						onChange={({ name, surname, patronymic, phone }) => {
							postal.setReceiverName(name);
							postal.setReceiverSurname(surname);
							postal.setReceiverPatronymicName(patronymic);
							postal.setReceiverPhone(phone);
						}}
					/>
				</div>
				<div>
					<Divider type="vertical" style={{ height: '100%' }} />
				</div>
				<div style={{ flex: 1 }}>
					<div style={{ display: 'flex', flexWrap: 'wrap', gap: '24px 16px', marginBottom: 24 }}>
						<Input placeholder="Тип отправления" style={{ width: 149 }} value={'Посылка'} disabled />
						<Input placeholder="Диапазон веса" style={{ width: 149 }} value={postal.weight.type_name ?? ''} disabled />
						<div style={{ position: 'relative' }}>
							<InputNumber
								placeholder="Вес"
								style={{ width: 149 }}
								required
								value={postal.weight.value}
								setValue={postal.setWeight}
								max={999.99}
								maxLength={6}
							/>
							<Icon
								imageName="DownLoad"
								color={colors.blue}
								style={{ position: 'absolute', right: 9, top: '50%', transform: 'translateY(-50%)' }}
							/>
						</div>
						<InputNumber
							placeholder="Высота"
							style={{ width: 149 }}
							value={postal.height}
							setValue={postal.setHeight}
							max={999.99}
							maxLength={6}
						/>
						<InputNumber
							placeholder="Длина"
							style={{ width: 149 }}
							value={postal.length}
							setValue={postal.setlength}
							max={999.99}
							maxLength={6}
						/>
						<InputNumber
							placeholder="Ширина"
							style={{ width: 149 }}
							value={postal.width}
							setValue={postal.setWidth}
							max={999.99}
							maxLength={6}
						/>
					</div>
					<div>
						<CheckParameter
							label="Наложенный платеж"
							infoBrief={descr.payment.brief}
							infoFull={descr.payment.full}
							showInput
							checked={!!postal.additional_services.is_cash_on}
							onCheck={postal.togglePayment}
							inputValue={postal.additional_services.payment_amount ?? ''}
							onValueChange={(value) => postal.setPaymentAmount(value ? `${value}` : '')}
							price={postal.additional_services.payment_price ?? ''}
						/>
						<CheckParameter
							label="Объявленная ценность"
							infoBrief={descr.declared.brief}
							infoFull={descr.declared.full}
							showInput
							checked={!!postal.additional_services.is_declared}
							inputValue={postal.additional_services.declared_amount ?? ''}
							onCheck={postal.toggleDeclared}
							onValueChange={(value) => postal.setDeclaredAmount(value ? `${value}` : '')}
							price={postal.additional_services.declared_price ?? ''}
						/>
						<CheckParameter
							label="Опись"
							infoBrief={descr.inventory.brief}
							infoFull={descr.inventory.full}
							showInput
							checked={!!postal.additional_services.is_inventory}
							onCheck={postal.toggleInventory}
							price={postal.additional_services.inventory_price ?? ''}
							inputValue={postal.additional_services.inventory_amount ?? ''}
							onValueChange={(value) => postal.setInventoryAmount(value ? `${value}` : '')}
						/>
						<CheckParameter
							label="Хрупкость"
							infoBrief={descr.fragile.brief}
							infoFull={descr.fragile.full}
							message="Проверьте целостность вложения!"
							checked={!!postal.additional_services.is_fragile}
							onCheck={postal.toggleFragile}
							price={postal.additional_services.fragile_price ?? ''}
						/>
						<CheckParameter
							label="Громоздкость"
							infoBrief={descr.oversize.brief}
							infoFull={descr.oversize.full}
							checked={!!postal.additional_services.is_oversize}
							onCheck={postal.toggleOversize}
							price={postal.additional_services.oversize_price ?? ''}
						/>
						<CheckParameter
							label="Проверка комплектности"
							infoBrief={descr.completeness.brief}
							infoFull={descr.completeness.full}
							checked={!!postal.additional_services.is_completeness}
							onCheck={postal.toggleCompleteness}
							price={postal.additional_services.completeness_price ?? ''}
						/>
						{!!postal.is_juristic && (
							<CheckParameter
								label="Перемаркировка"
								infoBrief={descr.relabelling.brief}
								infoFull={descr.relabelling.full}
								checked={!!postal.additional_services.is_relabeling}
								onCheck={postal.toggleRelabeling}
								price={
									postal.additional_services.relabeling_price ? `${postal.additional_services.relabeling_price}` : ''
								}
							/>
						)}
						<PackTags
							onAddClick={addPackClick}
							options={postal.packaging.slice()}
							onDelete={(id) => postal.setPackagingQuantity(id, 0)}
						/>
						{postal.message && (
							<div
								style={{
									padding: '8px 16px',
									borderWidth: 1,
									borderColor: colors.dustRed2,
									background: colors.dustRed1,
									color: colors.red,
									borderStyle: 'solid',
									marginBottom: 16,
								}}
							>
								{postal.message}
							</div>
						)}
						<Summary tariff={postal.tariff_price} pack={postal.packing_price} final={postal.final_price} />
					</div>
				</div>
			</div>
		</OverlayScrollbarsComponent>
	);
};

export default observer(PostalProcessForm);
