import Button from '@components/buttons/button';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import Icon from '@images/index';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import styles from './index.module.scss';

const PostalProcessButtons: FC = () => {
	const { visit: visitNum } = useParams();
	const visit = useCurrentVisit();

	const onSave = (isShowlabel?: boolean): void => {
		if (visit?.processedPostal) visit.processedPostal.accept(visitNum, isShowlabel);
	};

	return (
		<div className={styles.wrap}>
			<Button onClick={() => visit?.endProcessPostal(true)}>Отмена</Button>
			<Button
				type="primary"
				disabled={!visit?.processedPostal?.isDataValid || visit.processedPostal.acceptLock}
				onClick={() => onSave()}
			>
				Сохранить
			</Button>
			<Button
				type="primary"
				className={styles.button__icon}
				disabled={!visit?.processedPostal?.isDataValid || visit.processedPostal.acceptLock}
				onClick={() => onSave(true)}
			>
				Сохранить + <Icon imageName="Print" color="inherit" fontSize={16} className={styles.icon} />
			</Button>
		</div>
	);
};

export default observer(PostalProcessButtons);
