import Modal from '@components/modal';
import Select from '@components/select';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const SelectPrinterModal: FC = () => {
	const store = useStore();

	const savePrinters = () => {
		store.print.savePrinters();
	};

	return (
		<Modal
			title="Выберите принтер для печати"
			okText="Сохранить"
			cancelText="Отмена"
			onOk={savePrinters}
			onCancel={store.print.closePrinterSelect}
			open={store.print.openPrinterSelect}
			centered
			width={540}
			destroyOnClose
		>
			<div>
				<div className={styles.item}>
					<div>Термоярлык</div>
					<PrinterSelect value={store.print.termo} onChange={(value) => store.print.setTermo(value)} />
				</div>
				<div className={styles.item}>
					<div>Остальное</div>
					<PrinterSelect value={store.print.others} onChange={(value) => store.print.setOthers(value)} />
				</div>
			</div>
		</Modal>
	);
};

const PrinterSelect: FC<{ value: string | null; onChange: (value: string) => void }> = observer(
	({ value, onChange }) => {
		const store = useStore();
		return (
			<Select
				options={store.print.availablePrinters.map((value) => ({ label: value, value }))}
				placeholder="Выберите принтер"
				onChange={(value) => onChange(String(value))}
				value={value}
			/>
		);
	}
);

export default observer(SelectPrinterModal);
