import 'dayjs/locale/ru';

import Button from '@components/buttons/button';
import { useMessage } from '@hooks/useMessage';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { ReportsService } from '@services/reports';
import { DatePicker, Layout, Select } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { addDownloadLink } from 'utils/addDownloadLink';
import { addZero } from 'utils/addZero';

import PdfModal from '../visits/components/visit/compoments/pdf-modal';

const Reports: FC = () => {
	const store = useStore();
	const [open, contextHolder] = useMessage();
	const [date, setDate] = useState<dayjs.Dayjs | null>(dayjs());
	const dateString = (date && `${addZero(date?.year())}${addZero(date?.month() + 1)}${addZero(date?.date())}`) || '';

	const previewReport = (type?: number): void => {
		if (!dateString) return;
		store.setLoading(true);
		try {
			if (type) {
				store.print.setPrintPreview({
					type: type === 2 ? 'cash-register-report-pdf-employees-general' : 'cash-register-report-pdf-employees-full',
					date: dateString,
					id: +dateString || null,
				});
			} else {
				store.print.setPrintPreview({
					type: 'cash-register-report-pdf',
					date: dateString,
					id: +dateString || null,
				});
			}
		} catch (error: any) {
			open(error?.message || error?.error || 'Ошибка');
		} finally {
			store.setLoading(false);
		}
	};

	const downloadFile = async (type: number | null): Promise<void> => {
		if (!dateString) return;
		store.setLoading(true);
		let file: ArrayBuffer;

		try {
			if (type) {
				const res = await ReportsService.getReportPostalsXlsx(dateString, type);
				file = res.data;
			} else {
				const res = await ReportsService.getReportOrdersXlsx(dateString);
				file = res.data;
			}
			file && addDownloadLink(file, `${dateString}.xls`);
		} catch (error: any) {
			open(error?.message || error?.error || 'Ошибка');
		} finally {
			store.setLoading(false);
		}
	};
	return (
		<>
			<Layout>
				<Header style={{ background: '#fff', height: 72, padding: '16px 24px', display: 'flex', alignItems: 'center' }}>
					<h4>Отчеты</h4>
				</Header>
				<Content style={{ margin: 24, padding: 24, background: '#fff', flex: '0 0 140px' }}>
					<div style={{ marginBottom: 24 }}>
						<DatePicker value={date} onChange={(value) => setDate(value)} format="DD.MM.YYYY" />
					</div>
					<div style={{ display: 'flex', gap: 16 }}>
						<Select
							style={{ width: 301 }}
							value="Сменный итог по почтовым услугам"
							options={[
								{ value: '2', label: 'Общий' },
								{ value: '1', label: 'Детальный' },
							]}
							disabled={!date}
							onChange={(value) => value && previewReport(+value)}
						/>
						<Button disabled={!date} onClick={() => previewReport()}>
							Сменный итог по заказам
						</Button>
					</div>
				</Content>
			</Layout>
			<PdfModal
				footer={
					<>
						<Button onClick={store.print.cancelPrintPreview}>Закрыть</Button>
						<Button
							onClick={() => {
								const type = store.print.printPreview?.type;
								downloadFile(
									type !== 'cash-register-report-pdf'
										? type === 'cash-register-report-pdf-employees-general'
											? 2
											: 1
										: null
								);
							}}
							icon={<Icon imageName="DownLoad" />}
						>
							Скачать
						</Button>
						<Button
							icon={<Icon imageName="Print" />}
							type="primary"
							onClick={() => store.print.printPreview && store.print.printTask(toJS(store.print.printPreview))}
							disabled={!store.print.pdfPreviewDataId}
						>
							Печать
						</Button>
					</>
				}
			/>
			{contextHolder}
		</>
	);
};

export default observer(Reports);
