import Input from '@components/input';
import Select, { SelectProps } from '@components/select';
import StoreService from '@services/store';
import { InputRef } from 'antd';
import { CSSProperties, FC, useEffect, useRef, useState } from 'react';

import styles from './index.module.scss';

type Stores = Awaited<ReturnType<typeof StoreService.getStores>>['data'];

const PostOfficeSelection: FC<
	SelectProps & {
		getParamStore?: (val: any) => void;
		needParamStore?: string;
		isOnlyLarge?: boolean;
		wrapperStyle?: CSSProperties;
		isMarketAddressId?: boolean;
	}
> = ({ needParamStore, getParamStore, isOnlyLarge = false, wrapperStyle, isMarketAddressId = true, ...props }) => {
	const [stores, setStores] = useState<Stores>([]);
	const allStores = useRef<Stores>([]);
	const inputRef = useRef<InputRef>(null);

	const onInput: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
		setStores(allStores.current.filter((el) => el.name.toLowerCase().includes(value.toLocaleLowerCase())));
	};

	useEffect(() => {
		(async () => {
			const stores = await StoreService.getStores(isMarketAddressId);
			allStores.current = stores.data;
			setStores(stores.data);
		})();
	}, []);

	useEffect(() => {
		if (getParamStore && needParamStore && props.value) {
			const currentStore = stores.find((store) => store.id === props.value);
			//@ts-ignore
			currentStore && getParamStore(currentStore[needParamStore]);
		}
	}, [props.value, stores]);

	return (
		<Select
			dropdownRender={(menu) => (
				<>
					<div style={{ padding: 8 }}>
						<Input onChange={onInput} ref={inputRef} />
					</div>
					<div className={styles.options}>{menu}</div>
				</>
			)}
			{...props}
			options={stores
				.filter(({ is_large_weight_limit: isLimit }) => (isOnlyLarge ? isLimit : true))
				.map((store) => ({ value: store.id, label: store.name }))}
			onDropdownVisibleChange={(open) => setTimeout(() => open && inputRef.current && inputRef.current.focus(), 200)}
			className={styles.select}
			wrapperStyle={wrapperStyle}
		/>
	);
};

export default PostOfficeSelection;
