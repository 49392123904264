import Icon from '@images/index';
import { Modal as AntModal, ModalProps } from 'antd';
import cn from 'classnames';
import { FC } from 'react';

import styles from './index.module.scss';

const Modal: FC<ModalProps & { hasLines?: boolean }> = ({
	children,
	className,
	title = 'Модальное окно',
	hasLines = true,
	...props
}) => {
	return (
		<AntModal
			{...props}
			title={<h2 className={styles.title}>{title}</h2>}
			closeIcon={<Icon imageName="WinClose" fontSize={16} />}
			className={cn(styles.modal, { [styles.modal__no_lines]: !hasLines })}
			destroyOnClose
		>
			{children && <div className={cn(className, styles.body)}>{children}</div>}
		</AntModal>
	);
};

export default Modal;
