import { getSnapshot, types } from 'mobx-state-tree';

import Visit from './visit';

const allNums = ['1', '2', '3', '4'];

const { model, map, optional, boolean } = types;

const Visits = model({ visitMap: map(Visit), isConfirmModal: optional(boolean, false) })
	.actions((self) => ({
		addVisit() {
			const freeNums = allNums.filter((x) => !Array.from(self.visitMap.keys()).includes(x));
			self.visitMap.set(String(freeNums[0]), {});
			return freeNums[0];
		},
		closeVisit(visitNum: string) {
			if (self.visitMap.get(visitNum)?.postals.find(({ market_status: status }) => status === 10 || status === 15)) {
				self.isConfirmModal = true;
			} else {
				this.deleteVisit(visitNum);
			}
		},
		deleteVisit(visitNum: string) {
			const shiftedVisits = Array.from(self.visitMap.keys());
			shiftedVisits.forEach((item) => {
				const removing = self.visitMap.get(item);
				self.visitMap.delete(item);
				if (item !== visitNum) {
					self.visitMap.set(item, removing ? getSnapshot(removing) : {});
				}
			});
		},
		openConfirmModal(visitNum: string | undefined) {
			self.isConfirmModal = false;
			visitNum && this.deleteVisit(visitNum);
		},
		closeConfirmModal() {
			self.isConfirmModal = false;
		},
	}))
	.views((self) => ({
		get minVisitId() {
			return Math.min(...Array.from(self.visitMap.keys()).map((v) => Number(v)));
		},
	}));

export default Visits;
