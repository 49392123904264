import Search from '@components/search';
import colors from '@config/colors';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { useWindowSize } from '@hooks/useWindowSize';
import SearchService from '@services/search';
import { ConfigProvider } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import Option from './components/option';
import styles from './index.module.scss';

let controller: AbortController;
let timer: any;

const ClientSearch: FC = () => {
	const selectRef = useRef(null);
	const [options, setOptions] = useState<any[]>([]);
	const [value, setValue] = useState<string>();
	const [error, setError] = useState<string | null>(null);
	const [search, setSearch] = useState(false);
	const visit = useCurrentVisit();
	const { height } = useWindowSize();
	const { visit: visitKey } = useParams();

	const onSearch = (value: string): void => {
		if (!value.trim()) {
			setOptions([]);
			setValue('');
		}

		if (value.trim().length < 3) return;

		setError(null);
		clearTimeout(timer);
		setSearch(true);

		timer = setTimeout(async () => {
			try {
				if (controller) controller.abort();
				controller = new AbortController();
				const res = await SearchService.findPostal(value, controller.signal);
				setOptions([
					...res.data.postalItems.map((postal) => ({
						value: postal.id,
						label: (
							<Option
								type={postal.type}
								number={postal.number}
								receiverName={postal.receiver_fio}
								receiverPhone={postal.receiver_phone}
								senderName={postal.sender_fio}
								senderPhone={postal.sender_phone}
								key={postal.id}
								selectString={value}
							/>
						),
						item: postal,
					})),
					...res.data.orders.map((order) => ({
						value: order.id,
						label: (
							<Option
								key={order.id}
								type={3}
								number={order.market_id}
								receiverPhone={order.receiver_phone}
								receiverName={order.receiver_fio}
								selectString={value}
							/>
						),
						item: order,
					})),
				]);
			} catch (err: any) {
				if (err) {
					if (err.code !== 'ERR_CANCELED') {
						console.error('error', err);
						setOptions([]);
						setSearch(false);
					}
					//@ts-ignore
					setError(err.message || null);
				}
			} finally {
				setSearch(false);
			}
		}, 700);
	};

	const onSelect = (id: string, option: any): void => {
		setValue('');
		setOptions([]);
		visit?.loadPostals(id, 'type' in option.item ? 'postal' : 'order');
		//@ts-ignore
		selectRef.current?.blur();
	};

	useEffect(() => {
		setOptions([]);
		setValue('');
		setError(null);
	}, [visitKey]);

	return (
		<ConfigProvider
			theme={{
				token: {
					boxShadowSecondary:
						'0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
				},
			}}
		>
			<Search
				search={search}
				ref={selectRef}
				placeholder="Поиск"
				className={styles.select}
				style={{ width: 596 }}
				options={options}
				onSearch={onSearch}
				value={value}
				onChange={setValue}
				onSelect={onSelect}
				listHeight={height - 270}
				dropdownStyle={{ padding: 0 }}
				notFoundContent={
					(error && <p className={styles.error}>{error}</p>) ||
					(!!value && <div style={{ textAlign: 'center', color: colors.grey1, padding: 16 }}>Ничего не найдено</div>)
				}
			/>
		</ConfigProvider>
	);
};

export default ClientSearch;
