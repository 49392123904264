import { api } from '@http/index';
import { AxiosResponse } from 'axios';

interface Store {
	id: number;
	name: string;
	is_large_weight_limit: boolean;
}

const getStores = (isMarketAddressId = true): Promise<AxiosResponse<Store[]>> => {
	return api.get(`stores?${isMarketAddressId ? 'is_market_address_id=1' : ''}`);
};

const getQrCode = (): Promise<AxiosResponse<ArrayBuffer>> => api.get('/store/qr-code', { responseType: 'arraybuffer' });

const StoreService = { getStores, getQrCode };

export default StoreService;
