import { api } from '@http/index';
import { Report, ReportFull } from '@store/kassa';
import { AxiosResponse, GenericAbortSignal } from 'axios';

const getCashReport = (signal?: GenericAbortSignal): Promise<AxiosResponse<(typeof Report)[]>> => {
	return api.get(`cash-report`, { signal });
};

const getCashReportId = (id: number): Promise<AxiosResponse<(typeof ReportFull)[]>> => {
	return api.get(`cash-report/${id}`);
};

const postCashReport = (report: any): Promise<AxiosResponse<(typeof ReportFull)[]>> => {
	return api.post(`cash-report/create`, report);
};

export const KassaService = { getCashReport, getCashReportId, postCashReport };
