import { api } from '@http/index';
import { DocumentKeys } from '@pages/main/visits/components/visit/compoments/postal-table/components/documentTypes';
import { AxiosResponse } from 'axios';

const getAddressLabel = (id: number | string, type: DocumentKeys): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`label/${id}${type === 'label' ? '' : '/' + type}`, { responseType: 'arraybuffer' });

const getAddressMarketLabel = (number: string | number, type: DocumentKeys): Promise<AxiosResponse<ArrayBuffer>> =>
	api.post(`market-label/${type}`, { SerialNumber: [{ SerialNumber: number }] }, { responseType: 'arraybuffer' });

const getJuristicGroupAcceptance = (postalIds: number[]): Promise<AxiosResponse<ArrayBuffer>> =>
	api.post(
		'label/get-group-acceptance-receipt-for-legal-entities',
		{ postalItemId: postalIds },
		{ responseType: 'arraybuffer' }
	);

const getIndividualsGroupAcceptance = (postalIds: number[]): Promise<AxiosResponse<ArrayBuffer>> =>
	api.post(
		'label/get-group-acceptance-receipt-for-individuals',
		{ postalItemId: postalIds },
		{ responseType: 'arraybuffer' }
	);

const getGroupCashOnDelivery = (postalIds: number[]): Promise<AxiosResponse<ArrayBuffer>> =>
	api.post('label/get-group-issuing-cash-on-delivery', { postalItemId: postalIds }, { responseType: 'arraybuffer' });

const getIndividualsGroupIssuance = (postalIds: number[]): Promise<AxiosResponse<ArrayBuffer>> =>
	api.post(
		'label/get-group-issuance-receipt-for-individuals',
		{ postalItemId: postalIds },
		{ responseType: 'arraybuffer' }
	);

const getRefundAct = (id: number): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`label/refund-act/${id}`, { responseType: 'arraybuffer' });

const getPaymentAct = (id: number): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`label/payment-act/${id}`, { responseType: 'arraybuffer' });

const getClaimAct = (id: number): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`label/claim-act/${id}`, { responseType: 'arraybuffer' });

const getAutopsyAct = (id: number): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`label/autopsy-act/${id}`, { responseType: 'arraybuffer' });

const getDefectiveConditionAct = (id: number): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`label/defective-condition-act/${id}`, { responseType: 'arraybuffer' });

export const LabelService = {
	getAddressLabel,
	getAddressMarketLabel,
	getJuristicGroupAcceptance,
	getIndividualsGroupAcceptance,
	getGroupCashOnDelivery,
	getIndividualsGroupIssuance,
	getRefundAct,
	getPaymentAct,
	getClaimAct,
	getAutopsyAct,
	getDefectiveConditionAct,
};
