import IconButton from '@components/buttons/icon_btn';
import InputNumber from '@components/inputNumber';
import Tooltip from '@components/tooltip';
import colors from '@config/colors';
import Icon from '@images/index';
import { Checkbox } from 'antd';
import { FC } from 'react';

import styles from './index.module.scss';

export interface CheckParameterProps {
	label: string;
	message?: string;
	inputValue?: string;
	checked?: boolean;
	showInput?: boolean;
	showPrint?: boolean;
	infoBrief: string;
	infoFull?: string;
	price?: string;
	onCheck?: () => void;
	onPrint?: () => void;
	onValueChange?: (value: number | null) => void;
}

const CheckParameter: FC<CheckParameterProps> = ({
	message,
	label,
	inputValue,
	checked,
	showInput,
	showPrint,
	infoBrief,
	infoFull,
	price,
	onCheck,
	onPrint,
	onValueChange,
}) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				gap: 2,
				height: checked && message ? 80 : 50,
			}}
		>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
					<Checkbox onChange={onCheck} checked={checked}>
						{label}
					</Checkbox>
					<Tooltip text={infoBrief} fullText={infoFull}>
						<Icon imageName="Info" color={colors.grey3} className={styles.icon} />
					</Tooltip>
				</div>
				<div style={{ display: 'flex', alignItems: 'center', gap: 16, flexBasis: 116, justifyContent: 'flex-end' }}>
					{showPrint && <IconButton imageName="Print" onClick={onPrint} color={colors.grey1} />}
					{checked && showInput && onValueChange && (
						<InputNumber
							style={{ width: 80 }}
							required
							value={inputValue}
							setValue={onValueChange}
							max={99999.99}
							maxLength={6}
							wrapperStyle={{ flexBasis: 80 }}
						/>
					)}
				</div>
				{checked && <h5 style={{ flexBasis: 80, textAlign: 'center' }}>{Math.round(Number(price) * 100) / 100} </h5>}
			</div>
			{checked && message && <div style={{ color: colors.red }}>{message}</div>}
		</div>
	);
};

export default CheckParameter;
