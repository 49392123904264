import Button from '@components/buttons/button';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import PostOfficeSelection from '@pages/main/visits/components/visit/compoments/common/post-office-select';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const OpenShift: FC = () => {
	const store = useStore();
	return (
		<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<div className={styles.wrap}>
				<p className={styles.description}>
					Откройте смену, чтобы зафиксировать начало рабочего дня и получить доступ ко всему фунционалу
				</p>
				<PostOfficeSelection
					placeholder={store.postOffice ? 'ОПС' : 'Выберите ОПС'}
					onChange={(value) => store.setPostOffice(value as number)}
					value={store.postOffice}
					isMarketAddressId={false}
				/>
				<Button
					className={styles.button}
					type="primary"
					size="large"
					icon={<Icon imageName="Sun" />}
					disabled={!store.postOffice}
					onClick={store.openShift}
				>
					Открыть смену
				</Button>
			</div>
		</div>
	);
};

export default observer(OpenShift);
