import { Postal } from '@models/index';
import cn from 'classnames';
import { FC } from 'react';
import { priceFormat } from 'utils/priceFormat';

import styles from './index.module.scss';

const PostalItems: FC<Postal> = ({
	type,
	delivery_type: deliveryType,
	width,
	height,
	length,
	additional_services: {
		is_inventory: isInventory,
		inventory_amount: inventoryAmount,
		inventory_price: inventoryPrice,
		is_declared: isDeclared,
		declared_amount: declaredAmount,
		declared_price: declaredPrice,
		is_cash_on: isPayment,
		payment_amount: paymentAmount,
		payment_price: paymentPrice,
		is_fragile: isFragile,
		fragile_price: fragilePrice,
		is_completeness: isCompleteness,
		completeness_price: completenessPrice,
		is_oversize: isOversize,
		oversize_price: oversizePrice,
		is_relabeling: isRelabeling,
		relabeling_price: relabelingPrice,
	},
	tariff_price: tariffPrice,
	weight: { value: weightValue, type_name: weightType },
}) => {
	return (
		<ul className={styles.list}>
			{type > 1 ? (
				<li className={styles.item}>
					<span>Наложенный платеж</span>
					{!!paymentAmount && <span>{priceFormat(paymentAmount)}</span>}
					<span>{priceFormat(paymentPrice)}</span>
				</li>
			) : (
				<>
					<li className={cn(styles.item, styles.item__inner)}>
						<span>Посылка</span>
						<span>{deliveryType && deliveryType === 1 ? 'ОПС-ОПС' : 'ОПС-ДО ДВЕРИ'}</span>
					</li>
					{width && height && length && width + height + length > 0 ? (
						<li className={styles.item}>{`${width}*${height}*${length} см`}</li>
					) : null}
					{!!tariffPrice && (
						<li className={styles.item}>
							<span>
								Тариф
								{!!weightType && <span style={{ marginLeft: 16 }}>{weightType}</span>}
							</span>
							{!!weightValue && <span>{weightValue} кг</span>}
							<span>{priceFormat(tariffPrice)}</span>
						</li>
					)}
					{!!isInventory && (
						<li className={styles.item}>
							<span>Опись</span>
							{!!inventoryAmount && <span>{priceFormat(inventoryAmount)}</span>}
							<span>{priceFormat(inventoryPrice)}</span>
						</li>
					)}
					{!!isDeclared && (
						<li className={styles.item}>
							<span>Объявленная ценность</span>
							{!!declaredAmount && <span>{priceFormat(declaredAmount)}</span>}
							<span>{priceFormat(declaredPrice)}</span>
						</li>
					)}
					{!!isPayment && (
						<li className={styles.item}>
							<span>Наложенный платеж</span>
							{!!paymentAmount && <span>{priceFormat(paymentAmount)}</span>}
							<span>{priceFormat(paymentPrice)}</span>
						</li>
					)}
					{!!isFragile && (
						<li className={styles.item}>
							<span>Хрупкость</span>
							<span>{priceFormat(fragilePrice)}</span>
						</li>
					)}
					{!!isCompleteness && (
						<li className={styles.item}>
							<span>Проверка комплектности</span>
							<span>{priceFormat(completenessPrice)}</span>
						</li>
					)}
					{!!isOversize && (
						<li className={styles.item}>
							<span>Громоздкость</span>
							<span>{priceFormat(oversizePrice)}</span>
						</li>
					)}
					{!!isRelabeling && (
						<li className={styles.item}>
							<span>Перемаркировка</span>
							<span>{priceFormat(relabelingPrice)}</span>
						</li>
					)}
				</>
			)}
		</ul>
	);
};

export default PostalItems;
