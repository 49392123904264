/* eslint-disable @typescript-eslint/no-empty-interface */
import { Instance, SnapshotIn, types } from 'mobx-state-tree';

const { model, number, string, array, maybeNull } = types;

const OrderItem = model('OrderItem', {
	id: number,
	name: string,
	quantity: number,
	price: number,
	received_quantity: maybeNull(number),
	final_price: maybeNull(number),
}).actions((self) => {
	const calculatePrice = (): void => {
		if (!Number.isNaN(self.received_quantity) && self.received_quantity != null)
			self.final_price = +(self.received_quantity * self.price).toFixed(2);
	};
	return {
		increaseAmount() {
			if (self.received_quantity === null) {
				self.received_quantity = Number(self.quantity) + 1;
			} else {
				self.received_quantity = Number(self.received_quantity) + 1;
			}
			calculatePrice();
		},
		decreaseAmount() {
			if (self.received_quantity === null) {
				self.received_quantity = Number(self.quantity) - 1;
			} else if (Number(self.received_quantity) > 0) {
				self.received_quantity = Number(self.received_quantity) - 1;
			}
			calculatePrice();
		},
	};
});

const OrderItems = model('OrderItems', {
	id: number,
	receiver_fio: string,
	items: array(OrderItem),

	total_price: number,
	total_price_after_correction: maybeNull(number),
}).views((self) => ({
	get selectedKeys() {
		return self.items
			.filter((item) => item.received_quantity !== null && item.received_quantity !== item.quantity)
			.map(({ id }) => id);
	},
	get newPrice() {
		return +self.items
			.reduce((prev, { final_price }) => (final_price != null ? (prev += +final_price) : prev), 0)
			.toFixed(2);
	},
}));

export default OrderItems;

export interface OrderItems extends SnapshotIn<typeof OrderItems> {}

export interface OrderItem extends Instance<typeof OrderItem> {}
