import Badge from '@components/badge';
import TextSelectionName from '@components/text-selection-name';
import TextSelectionNumber from '@components/text-selection-number';
import TextSelectionPhone from '@components/text-selection-phone';
import Icon from '@images/index';
import { FC } from 'react';

import styles from './index.module.scss';

interface OptionProps {
	type: 0 | 1 | 2 | 3;
	number: string | number;
	senderPhone?: string;
	senderName?: string;
	receiverPhone: string;
	receiverName: string;
	selectString: string;
}

const Option: FC<OptionProps> = ({
	type,
	number,
	senderPhone,
	senderName,
	receiverPhone,
	receiverName,
	selectString,
}) => {
	return (
		<div className={styles.option}>
			<div className={styles.number}>
				{((type === 1 || type === 2) && <Icon imageName={type === 1 ? 'Mail' : 'Payments'} />) ||
					(type === 0 && (
						<Badge dot color="blue" offset={[-1, 3]}>
							<Icon imageName="Mail" />
						</Badge>
					)) ||
					(type === 3 && <Icon imageName="ShoppingBag" />)}
				<div>
					<b>
						<TextSelectionNumber text={String(number)} selection={selectString} />
					</b>
				</div>
			</div>
			<div className={styles.phone}>
				<span>{!!senderPhone && <TextSelectionPhone text={senderPhone} selection={selectString} />}</span>
				<span>
					<TextSelectionPhone text={receiverPhone} selection={selectString} />
				</span>
			</div>
			<div className={styles.name}>
				<span>{!!senderName && <TextSelectionName text={senderName} selection={selectString} />}</span>
				<span>
					<TextSelectionName text={receiverName} selection={selectString} />
				</span>
			</div>
		</div>
	);
};

export default Option;
