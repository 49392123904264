import { api } from '@http/index';
import { Shift } from '@store/shift';
import { AxiosResponse } from 'axios';

const openShift = (storeId: number): Promise<AxiosResponse<Shift>> => api.post('shift/open', { store_id: storeId });

const closeShift = (id: number): Promise<AxiosResponse<void>> => api.post(`shift/${id}/close`);

const openBreak = (id: number): Promise<AxiosResponse<Shift>> => api.post(`shift/${id}/break/open`);

const closeBreak = (shiftId: number, breakId: number): Promise<AxiosResponse<Shift>> =>
	api.post(`shift/${shiftId}/break/${breakId}/close`);

const getShift = (): Promise<AxiosResponse<Shift>> => api.get('shift/active');

const ShiftService = { openShift, closeShift, openBreak, closeBreak, getShift };

export default ShiftService;
