import { useStore } from '@hooks/useStore';
import { Layout } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import classNames from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC, useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';

import TasksHeader from './components/header';
import styles from './index.module.scss';

const Tasks: FC = () => {
	const [noShadow, setNoShadow] = useState(true);

	const store = useStore();
	useEffect(() => {
		store.loadTask();
	}, []);

	const ref = useRef<HTMLDivElement>(null);
	return (
		<Layout>
			<Header
				className={classNames(styles.menu_header, {
					[styles.menu_header__no_shadow]: noShadow,
				})}
			>
				<TasksHeader />
			</Header>
			<Content>
				<OverlayScrollbarsComponent
					className={styles.scroll}
					options={{ scrollbars: { autoHide: 'move' } }}
					events={{
						scroll: () => {
							setNoShadow(!ref.current?.parentElement?.scrollTop);
						},
					}}
				>
					<div ref={ref}>
						<Outlet />
					</div>
				</OverlayScrollbarsComponent>
			</Content>
		</Layout>
	);
};

export default Tasks;
