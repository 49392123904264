import { api } from '@http/index';
import { Order } from '@models/index';
import { OrderItems } from '@store/visits/order-items';
import { AxiosResponse } from 'axios';

const getOrders = (orderId: number): Promise<AxiosResponse<Order>> => {
	return api.get(`order/${orderId}/all`);
};

const getOrder = (orderId: number): Promise<AxiosResponse<Order>> => {
	return api.get(`order/${orderId}`);
};

const getOrderItems = ({ orderId }: { orderId: number }): Promise<AxiosResponse<OrderItems>> => {
	return api.get(`order/${orderId}/items`);
};

const cancelOrder = ({ orderId }: { orderId: number }): Promise<AxiosResponse<void>> => {
	return api.post(`order/${orderId}/cancel`);
};

const cancelCorrection = ({ orderId }: { orderId: number }): Promise<AxiosResponse<OrderItems>> => {
	return api.post(`order/${orderId}/correction/cancel`);
};

const saveCorrection = ({ orderId }: { orderId: number }): Promise<AxiosResponse<OrderItems>> => {
	return api.post(`order/${orderId}/correction`);
};

const changeOrderItem = ({
	orderId,
	itemId,
	count,
}: {
	orderId: number;
	itemId?: number;
	count?: number;
}): Promise<AxiosResponse<OrderItems>> => {
	return api.post(`/order/${orderId}/item/${itemId}/correction`, { received_quantity: count });
};

const confirmOrder = (data: {
	orders: number[];
	cash_payment: number;
	cashless_payment: number;
}): Promise<AxiosResponse<OrderItems>> => {
	return api.post(`order/received`, data);
};

const extendOrder = (id: number): Promise<AxiosResponse<{ message: string }>> => {
	return api.put(`/order/${id}/storage/extend`);
};

const OrderService = {
	getOrders,
	getOrder,
	getOrderItems,
	cancelOrder,
	changeOrderItem,
	cancelCorrection,
	saveCorrection,
	confirmOrder,
	extendOrder,
};

export default OrderService;
