import Button from '@components/buttons/button';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import Summary from './components/summary';
import styles from './index.module.scss';

const Compensation: FC = () => {
	const {
		compensations: { activeItem: item, openConfirmModal },
	} = useStore();

	if (!item) return null;

	return (
		<div className={styles.wrap}>
			<h2 className={styles.number}>
				<Icon imageName="Payments" />
				<span>{item.number}</span>
			</h2>
			<p className={styles.name}>{item.declarer_fio}</p>
			<span>{item.direction === 1 ? 'Получатель' : 'Отправитель'}</span>
			<p className={styles.reason}>Причина: {item.reason}</p>
			<Summary compensation={item.sum_compensation} refund={item.sum_refund} final={item.final_sum} />
			<Button className={styles.button} type="primary" onClick={openConfirmModal}>
				Подтвердить выдачу средств
			</Button>
		</div>
	);
};

export default observer(Compensation);
