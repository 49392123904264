import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import Button from '@components/buttons/button';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import cn from 'classnames';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { getGlobalBlob } from 'utils/globalObject';

import { DocumentKeys, documentTypes } from '../postal-table/components/documentTypes';
import styles from './index.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;
export interface PdfModalProps {
	data: { type: DocumentKeys; id: number; number: string };
}

const PdfModal: FC<{ footer?: React.ReactNode }> = ({ footer }) => {
	const store = useStore();
	const data = store.print.printPreview;
	const isOpen = !!store.print.printPreview;
	const [numPages, setNumPages] = useState<number>(0);
	const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => setNumPages(numPages);

	return (
		<Modal
			className={styles.body}
			// @ts-ignore
			title={data?.type ? `${documentTypes[data.type]} ${data.number || ''}` : ''}
			open={isOpen}
			onCancel={store.print.cancelPrintPreview}
			centered
			width={1022}
			footer={
				<footer className={styles.footer}>
					{footer || (
						<>
							<Button onClick={store.print.cancelPrintPreview}>Закрыть</Button>
							<Button
								icon={<Icon imageName="Print" />}
								type="primary"
								onClick={() => store.print.printPreview && store.print.printTask(toJS(store.print.printPreview))}
								disabled={!store.print.pdfPreviewDataId}
							>
								Печать
							</Button>
						</>
					)}
				</footer>
			}
			destroyOnClose
		>
			<div className={styles.wrap}>
				{store.print.pdfPreviewDataId && (
					<>
						<Document
							className={cn(styles.label, { [styles.label__thermal]: data?.type === 'thermal' })}
							file={getGlobalBlob(store.print.printPreview?.taskId || '')}
							loading="Загрузка документа"
							onLoadSuccess={onDocumentLoadSuccess}
						>
							{Array.from({ length: numPages }, (_, index) => (
								<div className={styles.wrapperPage} key={`page_${index + 1}`}>
									<Page pageNumber={index + 1} width={650} height={590} scale={5} loading="Загрузка страницы" />
									{numPages > 1 && (
										<p style={{ textAlign: 'center' }}>
											{index + 1} из {numPages}
										</p>
									)}
								</div>
							))}
						</Document>
					</>
				)}
			</div>
		</Modal>
	);
};

export default observer(PdfModal);
