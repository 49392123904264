import Button from '@components/buttons/button';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import Icon from '@images/index';
import { Order, Postal } from '@models/index';
import { Tag, theme, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC, useState } from 'react';

import PostalInfoModal from '../postal-info-modal';
import Actions, { ActionsProps } from './components/actions';
import PostalBriefInfo, { PostalBriefInfoProps } from './components/brief';
import styles from './index.module.scss';

export interface PostalTableProps {
	title: string;
	data: PostalTableDataType[];
	hasClearButton?: boolean;
	onButtonClick?: (buttonType: PostalTableDataType['buttonType'], key: number) => void;
	onActionClick: (
		action: Parameters<ActionsProps['onClick']>[0],
		postalId: number,
		type: PostalTableDataType['type']
	) => void;
}

export interface PostalTableDataType {
	key: number;
	header: PostalBriefInfoProps;
	names: { sender?: string; receiver: string };
	phones: { sender?: string; receiver: string };
	info: string[];
	tags?: string[];
	buttonType?: 'process' | 'check';
	type: 'postal' | 'payment' | 'requistion' | 'order';
	postalObject?: Postal;
	orderObject?: Order;
}

const PostalTable: FC<PostalTableProps> = ({ title, data, hasClearButton, onButtonClick, onActionClick }) => {
	const { token } = theme.useToken();
	const visit = useCurrentVisit();
	const [selectedItem, setSelectedItem] = useState<PostalTableDataType | null>(null);

	const columns: ColumnsType<PostalTableDataType> = [
		{
			dataIndex: 'postal',
			className: styles.cell,
			width: 150 + 32,
			render: (_, { header }) => <PostalBriefInfo {...header} />,
		},
		{
			dataIndex: 'names',
			className: styles.cell,
			width: 248 + 32,
			render: (_, { names: { sender, receiver } }) => (
				<div style={{ width: 248 }}>
					<div>{sender}</div>
					<div>{receiver}</div>
				</div>
			),
		},
		{
			dataIndex: 'phones',
			className: styles.cell,
			width: 130 + 32,
			render: (_, { phones: { sender, receiver } }) => (
				<div style={{ width: 136 }}>
					<div>{sender}</div>
					<div>{receiver}</div>
				</div>
			),
		},
		{
			dataIndex: 'info',
			className: cn(styles.cell, styles.info),
			width: 158 + 32,
			render: (_, { info: [str1, str2] }) => (
				<div style={{ width: 158 }}>
					<div>{str1}</div>
					<div>{str2}</div>
				</div>
			),
		},
		{
			dataIndex: 'tags',
			className: styles.cell,
			render: (_, { tags, key, buttonType, type }) => {
				return type !== 'payment' ? (
					<div style={{ display: 'flex', paddingRight: 32 }}>
						<div style={{ flex: 1 }}>
							{tags?.map((tag) => (
								<Tag key={tag} style={{ marginBottom: 4 }}>
									{tag}
								</Tag>
							))}
						</div>
						<div style={{ paddingLeft: 32 }}>
							{buttonType && (
								<Button
									type="primary"
									onClick={(evt) => {
										evt.stopPropagation();
										onButtonClick && onButtonClick(buttonType, key);
									}}
									style={{ margin: 'auto' }}
								>
									{(buttonType === 'process' && 'Обработать') || (buttonType === 'check' && 'Проверка')}
								</Button>
							)}
						</div>
					</div>
				) : null;
			},
		},
		{
			dataIndex: 'actions',
			className: styles.cell,
			width: 36,
			render: (_, { key, type, header: { id }, postalObject, orderObject }) => (
				<Actions
					id={id}
					type={type}
					onClick={(action) => onActionClick(action, key, type)}
					postal={postalObject}
					order={orderObject}
				/>
			),
		},
	];

	return (
		<div className={styles.wrap}>
			<div
				className={styles.title}
				style={{
					borderRightColor: token.colorBorder,
				}}
			>
				<Typography className={styles.title_text}>{title}</Typography>
			</div>
			<OverlayScrollbarsComponent className={styles.scroll} options={{ scrollbars: { autoHide: 'move' } }}>
				<div style={{ paddingRight: 16 }}>
					<Table
						columns={columns}
						dataSource={data}
						showHeader={false}
						pagination={false}
						className={styles.table}
						rowClassName={styles.row}
						onRow={(obj) => ({
							onClick: () => {
								setSelectedItem(obj);
							},
						})}
						locale={{ emptyText: 'Нет данных' }}
					/>
				</div>
			</OverlayScrollbarsComponent>
			{hasClearButton && !!visit?.newReceptions.length && (
				<Button
					className={styles.clear}
					shape="round"
					icon={<Icon imageName="Delete" />}
					onClick={visit.clearNewReceptions}
				>
					Удалить из визита все необработанные заявки ({visit?.newReceptions.length})
				</Button>
			)}
			<PostalInfoModal
				id={selectedItem?.key || null}
				type={selectedItem?.type as 'postal' | 'order'}
				onClose={() => setSelectedItem(null)}
			/>
		</div>
	);
};

export default observer(PostalTable);
