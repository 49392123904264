import { FC } from 'react';
import { formatPhoneNumber } from 'utils/formatPhone';

import styles from './index.module.scss';

type BodyType = {
	title: string;
	fio: string;
	phone: string;
	address?: string | null | false;
};

const People: FC<BodyType> = ({ title, fio, phone, address }) => {
	return (
		<div className={styles.wrap}>
			<h3 className={styles.title}>{title}</h3>
			<p>{fio}</p>
			<span>{formatPhoneNumber(phone)}</span>
			{!!address && <p>{address}</p>}
		</div>
	);
};

export default People;
