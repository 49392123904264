import { useCurrentVisit } from '@hooks/useCurrentVisit';
// import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import ConfirmModal, { ItemsType } from './compoments/confirm-modal';
import Footer from './compoments/footer';
import IncidentModal from './compoments/incident-modal';
import OrderCorrectionModal from './compoments/order-correction-modal';
import PostalProcessingModal from './compoments/postal-modal';
import PostalTable, { PostalTableDataType, PostalTableProps } from './compoments/postal-table';
// import Services from './compoments/services';
// import styles from './index.module.scss';
import { getTableDataFromPostalsAndOrders } from './ts/getTableData';

const Visit: FC = observer(() => {
	const [modalCorrectionId, setModalCorrectionId] = useState<number | null>(null);
	const [confirmItems, setConfirmItems] = useState<ItemsType[]>([]);
	const visit = useCurrentVisit();
	const buttonClickHandler = (btnType: PostalTableDataType['buttonType'], postalId: number): void => {
		if (btnType === 'process') {
			visit?.processPostal(postalId);
		} else if (btnType === 'check') {
			setConfirmItems([
				{
					active: true,
					name: 'Проверка комплектности',
					number: visit?.getPostal(postalId)?.number ?? '',
					id: postalId,
				},
			]);
		}
	};
	const confirmCompleteness = (items: ItemsType[]): void => {
		items.forEach((item) => visit?.getPostal(item.id)?.confirmCompleteness(Number(item.active) as 0 | 1));
		setConfirmItems([]);
	};
	const actionHandler: PostalTableProps['onActionClick'] = (action, id, type) => {
		if (action === 'delete') {
			if (type !== 'order') {
				visit?.deletePostal(id);
			} else {
				visit?.deleteOrder(id);
			}
		}

		if (action === 'correction') setModalCorrectionId(id);
		if (action === 'incident') visit?.icidents?.setId(id);
	};
	if (visit && !visit.isEmpty) {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: 27,
					height: 'calc(100vh - 290px)',
					minHeight: 500,
					overflow: 'hidden',
				}}
			>
				<div style={{ display: 'flex', flexDirection: 'column', gap: 24, flex: 1, maxHeight: 'calc(100% - 150px)' }}>
					{!!visit.issues.length && (
						<PostalTable
							title="Выдать"
							data={getTableDataFromPostalsAndOrders(visit.issues)}
							onActionClick={actionHandler}
							onButtonClick={buttonClickHandler}
						/>
					)}
					{!!visit.receptions.length && (
						<PostalTable
							title="Принять"
							data={getTableDataFromPostalsAndOrders(visit.receptions)}
							onButtonClick={buttonClickHandler}
							onActionClick={actionHandler}
							hasClearButton
						/>
					)}
				</div>
				{/* <Services className={styles.services} /> */}
				<Footer />
				<PostalProcessingModal />
				{!!confirmItems.length && (
					<ConfirmModal
						items={confirmItems}
						open={!!confirmItems.length}
						onCancel={() => setConfirmItems([])}
						onConfirm={confirmCompleteness}
					/>
				)}
				{!!modalCorrectionId && (
					<OrderCorrectionModal
						id={modalCorrectionId}
						isOpen={!!modalCorrectionId}
						onClose={() => setModalCorrectionId(null)}
					/>
				)}
				{visit.icidents.id && <IncidentModal />}
			</div>
		);
	} else {
		return null;
	}
});

export default Visit;
