import Button from '@components/buttons/button';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import Icon from '@images/index';
import { Popover } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import ReactDOM from 'react-dom';

const ClearVisitButton: FC = observer(() => {
	const visit = useCurrentVisit();
	const [open, setOpen] = useState(false);

	const clickHandler = (btn: 'confirm' | 'cancel'): void => {
		if (btn === 'confirm') {
			setOpen(false);
			visit?.clear();
		} else {
			setOpen(false);
		}
	};

	if (visit?.isEmpty) return null;

	return (
		<>
			<Popover
				content={<ConfirmContent onClick={clickHandler} />}
				trigger="click"
				onOpenChange={(open) => setOpen(open)}
				open={open}
				placement="bottomRight"
			>
				<Button type="default" shape="default" icon={<Icon imageName="Delete" />}>
					Очистить визит
				</Button>
			</Popover>
			{open &&
				ReactDOM.createPortal(
					<div
						style={{
							position: 'fixed',
							background: '#000',
							opacity: 0.2,
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							transition: 'background 1s',
						}}
					></div>,
					document.body
				)}
		</>
	);
});

const ConfirmContent: FC<{ onClick: (btn: 'confirm' | 'cancel') => void }> = ({ onClick }) => (
	<>
		<div style={{ width: 223, marginBottom: 8 }}>Вы точно хотите очистить визит?</div>
		<div style={{ display: 'flex', gap: 8, justifyContent: 'flex-end' }}>
			<Button type="default" shape="default" onClick={() => onClick('cancel')}>
				Отмена
			</Button>
			<Button type="primary" shape="default" onClick={() => onClick('confirm')}>
				Да, очистить
			</Button>
		</div>
	</>
);

export default ClearVisitButton;
