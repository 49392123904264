import { api } from '@http/index';
import { AxiosResponse } from 'axios';

const closeVisit = (data: {
	postal_items: number[];
	cash_payment: number;
	cashless_payment: number;
}): Promise<AxiosResponse<any>> => {
	return api.post(`visit/close`, data);
};

const VisitService = { closeVisit };

export default VisitService;
