import { Button, message } from 'antd';

export const useMessage: (props?: { duration?: number }) => any = (props) => {
	const [messageApi, contextHolder] = message.useMessage({
		top: 64,
		duration: props?.duration || 7,
		prefixCls: 'messages',
	});
	const open = (message: string): ReturnType<typeof messageApi.open> =>
		messageApi.open({
			type: 'error',
			content: (
				<>
					<div>{message}</div>
					<Button type="primary" onClick={() => navigator.clipboard.writeText(message)}>
						Копировать
					</Button>
				</>
			),
			className: 'message',
		});
	return [open, contextHolder];
};
