import Button from '@components/buttons/button';
import IconButton from '@components/buttons/icon_btn';
import Modal from '@components/modal';
import colors from '@config/colors';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import Icon from '@images/index';
import { OrderItem } from '@store/visits/order-items';
import { ConfigProvider, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import styles from './index.module.scss';

type ModalType = {
	id: number | null;
	isOpen: boolean;
	onClose: () => void;
};

interface DataType {
	key: number;
	item: OrderItem;
}

const OrderCorrectionModal: FC<ModalType> = ({ id, isOpen, onClose }) => {
	const [confirm, setConfirm] = useState<'order' | 'correction' | null>(null);
	const visit = useCurrentVisit();
	useEffect(() => {
		id && visit?.loadOrderItems({ orderId: id }, 'getOrderItems');
	}, [id, visit]);

	const columns: ColumnsType<DataType> = [
		{
			title: 'Наименование товара',
			className: cn(styles.cell, styles.cell__name),
			dataIndex: 'name',
			render: (_, { item: { name } }) => <span>{name}</span>,
		},
		{
			title: 'Кол-во старое',
			className: styles.cell,
			dataIndex: 'quantity',
			align: 'right',
			render: (_, { item: { quantity } }) => <span>{quantity}</span>,
		},
		{
			title: 'Цена',
			className: styles.cell,
			dataIndex: 'price',
			render: (_, { item: { price } }) => <span>{price}</span>,
		},
		{
			title: 'Кол-во новое',
			className: styles.cell,
			dataIndex: 'received_quantity',
			render: (_, { item: { received_quantity, quantity } }) => (
				<span>{received_quantity === 0 ? 0 : received_quantity || quantity}</span>
			),
		},
		{
			title: 'Сумма',
			className: styles.cell,
			dataIndex: 'final_price',
			render: (_, { item: { final_price } }) => <span>{final_price}</span>,
		},
		{
			title: 'Коррекция',
			className: cn(styles.cell, styles.cell__correction),
			render: (_, { item: { received_quantity, id: itemId, quantity } }) => {
				const isZero = received_quantity === 0;
				const plusDisabled = isZero ? false : !received_quantity || received_quantity === quantity;

				return (
					<>
						<IconButton
							imageName="Minus"
							stroke={isZero ? colors.grey2 : colors.black}
							fontSize={20}
							onClick={() =>
								id &&
								visit?.loadOrderItems(
									{ orderId: id, itemId, count: +(received_quantity || quantity) - 1 },
									'changeOrderItem'
								)
							}
							disabled={isZero}
							btnStyle={{ padding: '2px 0' }}
						/>
						<IconButton
							imageName="Plus"
							stroke={plusDisabled ? colors.grey2 : colors.black}
							fontSize={20}
							onClick={() =>
								id &&
								visit?.loadOrderItems(
									{ orderId: id, itemId, count: received_quantity || isZero ? +received_quantity + 1 : +quantity + 1 },
									'changeOrderItem'
								)
							}
							disabled={plusDisabled}
							btnStyle={{ padding: '2px 0' }}
						/>
					</>
				);
			},
		},
	];

	return (
		<Modal
			title={
				<>
					<Icon imageName={'ShoppingBag'} /> {visit?.getOrder(id as number)?.market_id}
				</>
			}
			footer={
				<footer className={styles.footer}>
					<Button
						onClick={() => {
							id && visit?.loadOrderItems({ orderId: id }, 'cancelCorrection');
							onClose();
						}}
					>
						Закрыть без изменений
					</Button>
					<Button onClick={() => setConfirm('order')}>Отменить заказ</Button>
					<Button
						onClick={() => {
							id && visit?.loadOrderItems({ orderId: id }, 'saveCorrection');
							onClose();
						}}
						type="primary"
					>
						Сохранить изменения
					</Button>
				</footer>
			}
			open={isOpen}
			width={1085}
			centered
			onCancel={() => setConfirm('correction')}
		>
			<ConfigProvider
				theme={{
					components: { Table: { borderRadius: 0 } },
				}}
			>
				<Table
					className={styles.table}
					columns={columns}
					dataSource={visit?.orderItems?.items.map((item) => ({ key: item.id, item })) || []}
					caption={<p className={styles.table__caption}>{visit?.orderItems?.receiver_fio || ''}</p>}
					footer={() => (
						<>
							<p className={styles.total}>
								<span className={styles.total__name}>Сумма заказа</span>
								<span className={styles.total__sum}>{visit?.orderItems?.total_price}</span>
							</p>
							{(visit?.orderItems?.total_price_after_correction ||
								visit?.orderItems?.total_price_after_correction === 0) && (
								<p className={cn(styles.total, styles.total__correction)}>
									<span className={styles.total__name}>После коррекции</span>
									<span className={styles.total__sum}>{visit?.orderItems?.total_price_after_correction}</span>
								</p>
							)}
						</>
					)}
					pagination={false}
					rowClassName={styles.row}
					onHeaderRow={() => ({ className: styles.row__head })}
					rowSelection={{ selectedRowKeys: visit?.orderItems?.selectedKeys, columnWidth: 0 }}
					locale={{ emptyText: 'Нет данных' }}
				/>
				<Modal
					className={styles.confirm}
					title={
						confirm === 'order'
							? 'Вы уверены, что хотите отменить заказ?'
							: 'Вы уверены, что хотите закрыть окно коррекции?'
					}
					okText={confirm === 'order' ? 'Отменить заказ' : 'Да, закрыть'}
					cancelText="Отмена"
					open={!!confirm}
					onCancel={() => setConfirm(null)}
					onOk={() => {
						confirm === 'order'
							? id && visit?.loadOrderItems({ orderId: id }, 'cancelOrder').then(() => visit?.deleteOrder(id))
							: id && visit?.loadOrderItems({ orderId: id }, 'cancelCorrection');

						setConfirm(null);
						onClose();
					}}
					hasLines={false}
					centered
					width={416}
				>
					{confirm === 'correction' && 'Внесенные изменения не сохранятся'}
				</Modal>
			</ConfigProvider>
		</Modal>
	);
};

export default observer(OrderCorrectionModal);
