import { Layout } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import IdentificationHeader from './components';
import PostalBrefInfo from './components/postal-bref-info';
import styles from './index.module.scss';

const Identification: FC = () => {
	return (
		<Layout>
			<Header className={styles.header}>
				<IdentificationHeader />
			</Header>
			<Content className={styles.content}>
				<PostalBrefInfo />
			</Content>
		</Layout>
	);
};

export default observer(Identification);
