/* eslint-disable @typescript-eslint/no-empty-interface */
import { SnapshotIn, types } from 'mobx-state-tree';

const { model, number, string, maybeNull, array } = types;

const Order = model('Order', {
	bags: array(
		model('Bags', {
			id: number,
			market_id: number,
			name: string,
		})
	),
	certificate_sum: maybeNull(string),
	payment_type: maybeNull(number),
	id: number,
	market_id: number,
	status_id: number,
	status_name: string,
	receiver_fio: string,
	receiver_phone: string,
	sum: maybeNull(string),
	payment_affiliation: maybeNull(number),
	store_name: string,
});

export default Order;

export interface Order extends SnapshotIn<typeof Order> {}
