import { api } from '@http/index';
import { Payment } from '@store/visits/payment';
import { AxiosResponse } from 'axios';

const getPayment = (orders: number[], postal_items: number[]): Promise<AxiosResponse<Payment>> =>
	api.post('payment', { orders, postal_items });

const PaymentService = { getPayment };

export default PaymentService;
