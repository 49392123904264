/* eslint-disable @typescript-eslint/no-empty-interface */
import { SnapshotIn, types } from 'mobx-state-tree';

const { model, number, maybeNull } = types;

const Shift = model('Shift', { shift_id: number, status: number, break_id: maybeNull(number) });

export default Shift;

export interface Shift extends SnapshotIn<typeof Shift> {}
