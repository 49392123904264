import Icon from '@images/index';
import { AutoComplete } from 'antd';
import { FC } from 'react';

import styles from './index.module.scss';

type SearchProps = Parameters<typeof AutoComplete<any>>[0] & { search?: boolean };

const Search: FC<SearchProps> = ({ search, ...props }) => {
	return (
		<div style={{ position: 'relative', display: 'inline-block' }}>
			<AutoComplete {...props} />
			{search && <Icon imageName="Spinner" className={styles.spinner} />}
		</div>
	);
};

export default Search;
