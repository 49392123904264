import colors, { Color } from '@config/colors';
import { Badge } from 'antd';
import Icon, { ImageName } from 'images';
import { FC } from 'react';

export interface PostalBriefInfoProps {
	image: Extract<ImageName, 'Mail' | 'Payments' | 'ShoppingBag'>;
	number: string | number;
	status: string;
	id: number;
	statusColor?: Extract<Color, 'blue' | 'green'>;
	dot?: boolean;
}

const PostalBriefInfo: FC<PostalBriefInfoProps> = ({ image, number, status, statusColor = 'blue', dot }) => {
	return (
		<div style={{ display: 'flex', gap: 10, width: 150 }}>
			<div style={{ paddingTop: 5 }}>
				{(dot && (
					<Badge dot color="blue" offset={[-1, 3]}>
						<Icon imageName={image} />
					</Badge>
				)) || <Icon imageName={image} />}
			</div>
			<div>
				<div>
					<b>{number}</b>
				</div>
				<div style={{ color: colors[statusColor] }}>{status}</div>
			</div>
		</div>
	);
};

export default PostalBriefInfo;
