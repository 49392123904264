/* eslint-disable @typescript-eslint/no-empty-interface */
import OrderService from '@services/order';
import PaymentService from '@services/payment';
import { PostalService } from '@services/postal';
import SearchService from '@services/search';
import VisitService from '@services/visit';
// import { PrintTask } from '@store/print';
import { applySnapshot, flow, getRoot, Instance, types } from 'mobx-state-tree';

import { RootStore } from '..';
import CreateIncident, { CreateIncidentModel } from './create-incident';
import CreateOrder from './create-order';
import IncidentList from './incidents';
import Order from './order';
import OrderItems from './order-items';
import Payment from './payment';
import Postal from './postal';

const { model, array, maybeNull, map, boolean, number, optional, string } = types;

// const checkPdfModalData = ({ id, number, type, isCashOn, isJuristic }: any): PrintTask[] => {
// 	const arr: PrintTask[] = [];
// 	// if (type === 0 && isJuristic === 0) {
// 	// 	arr.push({ type: 'acceptance-for-individuals', number: number || '', id });
// 	// }
// 	// if (type === 0 && isJuristic === 1) {
// 	// 	arr.push({ type: 'get-group-acceptance-receipt-for-legal-entities', number: number || '', id });
// 	// }
// 	if (type === 1 && isCashOn === 1) {
// 		arr.push({ type: 'np-acceptance-receipt', number: number || '', id });
// 	}
// 	// if (type === 1) {
// 	// 	arr.push({ type: 'software-receipt', number: number || '', id });
// 	// }
// 	// if (type === 2) {
// 	// 	arr.push({ type: 'receipt-issuence-np', number: number || '', id });
// 	// }
// 	return arr;
// };

const Visit = model('visit', {
	postals: array(Postal),
	orders: array(Order),
	createOrderModel: maybeNull(CreateOrder),
	processedPostal: maybeNull(Postal),
	confirmedInventory: map(boolean),
	payment: maybeNull(Payment),
	paidInCash: maybeNull(number),
	paidInCard: maybeNull(number),
	orderItems: maybeNull(OrderItems),
	icidents: optional(IncidentList, { id: null, list: null }),
	createIncidentModel: maybeNull(CreateIncident),
	confirmModal: maybeNull(string),
})
	.actions((self) => ({
		loadPayment: flow(function* () {
			const root = getRoot<typeof RootStore>(self);
			const postalIds = self.postals.map(({ id }) => id);
			const orderIds = self.orders.map(({ id }) => id);
			root.setPaymentError(false);
			try {
				const res = yield PaymentService.getPayment(orderIds, postalIds);
				if (res.data.error) {
					root.setPaymentError(true);
					root.setErrorMessage(String(res.data.error?.error || 'Ошибка в /payment'));
				} else {
					self.payment = res.data;
				}
			} catch (error: any) {
				console.log('payment', error);
				root.setPaymentError(true);
				root.setErrorMessage(String(error?.error || error?.message || ''));
			}
		}),
	}))
	.actions((self) => {
		const root = getRoot<typeof RootStore>(self);
		return {
			loadPostals: flow(function* (postalId, type) {
				try {
					root.setLoading(true);
					const res = yield SearchService.findAdditional(postalId, type);
					self.postals = res.data.postalItems;
					self.orders = res.data.orders;
					self.paidInCash = null;
					self.paidInCard = null;
					yield self.loadPayment();
				} finally {
					root.setLoading(false);
				}
			}),
			createOrder() {
				self.createOrderModel = CreateOrder.create();
			},
			cancelOrder() {
				self.createOrderModel = null;
				this.endProcessPostal(true);
			},
			clear() {
				self.orders.clear();
				self.postals.clear();
			},
			processPostal: flow(function* (postalId: number) {
				try {
					root.setLoading(true);
					const postal = yield PostalService.getPostalInfo(postalId);
					self.processedPostal = Postal.create(postal.data);
					yield self.processedPostal.setCalculate();
				} catch (e) {
					console.error(e);
				} finally {
					root.setLoading(false);
				}
			}),
			endProcessPostal(noLoad?: boolean) {
				!noLoad && this.loadPostals(self.processedPostal?.id, 'postal');
				if (self.createOrderModel?.clientId) {
					self.createOrderModel = null;
				}
				self.processedPostal = null;
			},
			deletePostal(postalId: number) {
				const postal = self.postals.find(({ id }) => id === postalId);
				if (postal) {
					self.postals.remove(postal);
					self.loadPayment();
				}
			},
			changePostal(postalId: number, items: any) {
				const postalIndex = self.postals.findIndex(({ id }) => id === postalId);
				const postal = self.postals[postalIndex];

				if (postal) {
					applySnapshot(self.postals[postalIndex], { ...postal, ...items });
				}
			},
			deleteOrder(orderId: number) {
				const order = self.orders.find(({ id }) => id === orderId);
				if (order) {
					self.orders.remove(order);
					self.loadPayment();
				}
			},
			confirmInventory(id: number, value: boolean) {
				self.confirmedInventory.set(String(id), value);
			},
			confirmOrders: flow(function* () {
				try {
					root.setLoading(true);
					yield OrderService.confirmOrder({
						orders: self.orders.map(({ id }) => id),
						cash_payment: self?.paidInCash || 0,
						cashless_payment: self?.paidInCard || 0,
					});
					self.confirmModal = 'Визит успешно обработан';
					// self.orders.forEach(({ id, market_id }) =>
					// 	getRoot<typeof RootStore>(self).print.printTask({ type: 'sales-receipt', number: String(market_id), id })
					// );

					self.orders.clear();
				} catch (data: any) {
					if (data?.message) {
						return Promise.reject(data?.message);
					}

					self.orders.forEach((order) => {
						if (data?.success_market_id.includes(order.id)) {
							order.status_id = 90;
							order.status_name = 'Выдан';
							// getRoot<typeof RootStore>(self).print.printTask({
							// 	type: 'sales-receipt',
							// 	number: String(order.market_id),
							// 	id: order.id,
							// });
						}
						if (order.id === data.id) {
							order.status_id = 90;
							order.status_name = 'Ошибка';
						}
					});

					self.loadPayment();
					return Promise.reject(data?.error);
				} finally {
					root.setLoading(false);
				}
			}),
			clearNewReceptions() {
				self.postals.forEach((item) => {
					if (item.type === 0 && item.market_status === 0) {
						self.postals.remove(item);
					}
				});
				self.loadPayment();
			},
			close: flow(function* () {
				try {
					root.setLoading(true);
					yield VisitService.closeVisit({
						postal_items: self.postals.map(({ id }) => id),
						cash_payment: self?.paidInCash || 0,
						cashless_payment: self?.paidInCard || 0,
					});
					self.confirmModal = 'Визит успешно обработан';
					// const forPrint = self.postals.filter(({ type, is_juristic }) => !(type === 0 && is_juristic === 1));
					const paymentIds = self.postals.filter(({ type }) => type === 2).map(({ id }) => id);
					const jurIds = self.postals
						.filter(({ type, is_juristic }) => type === 0 && is_juristic === 1)
						.map(({ id }) => id);
					const fizIds = self.postals
						.filter(({ type, is_juristic }) => type === 0 && is_juristic === 0)
						.map(({ id }) => id);
					const fizPostalIds = self.postals.filter(({ type }) => type === 1).map(({ id }) => id);

					if (paymentIds.length) {
						getRoot<typeof RootStore>(self).print.printTask({
							ids: paymentIds,
							type: 'get-group-issuing-cash-on-delivery',
						});
					}

					if (jurIds.length) {
						getRoot<typeof RootStore>(self).print.printTask({
							ids: jurIds,
							type: 'get-group-acceptance-receipt-for-legal-entities',
						});
					}

					if (fizIds.length) {
						getRoot<typeof RootStore>(self).print.printTask({
							ids: fizIds,
							type: 'get-group-acceptance-receipt-for-individuals',
						});
					}

					if (fizPostalIds.length) {
						getRoot<typeof RootStore>(self).print.printTask({
							ids: fizPostalIds,
							type: 'get-group-issuance-receipt-for-individuals',
						});
					}

					// forPrint.forEach(
					// 	({ id, number, type, additional_services: { is_cash_on: isCashOn }, is_juristic: isJuristic }) => {
					// 		const pdfs = checkPdfModalData({ id, number, type, isCashOn, isJuristic });
					// 		pdfs.forEach((pdf) => getRoot<typeof RootStore>(self).print.printTask(pdf));
					// 	}
					// );

					self.postals.clear();
				} catch (data: any) {
					if (data?.message) {
						return Promise.reject(data?.message);
					}

					self.postals.forEach((postal) => {
						if (data?.success_ids?.includes(postal.id)) {
							// const data = checkPdfModalData({
							// 	id: postal.id,
							// 	number: postal.number,
							// 	type: postal.type,
							// 	isCashOn: postal.additional_services.is_cash_on,
							// 	isJuristic: postal.is_juristic,
							// });
							// data.forEach((pdf) => getRoot<typeof RootStore>(self).print.printTask(pdf));
							self.postals.remove(postal);
						}
						if (data?.ids.includes(postal.id)) {
							postal.market_status_name = 'Ошибка';
						}
					});

					self.loadPayment();
					return Promise.reject(data?.error || null);
				} finally {
					root.setLoading(false);
				}
			}),
			payCash(value: number | null) {
				self.paidInCash = value;
			},
			payCard(value: number | null) {
				self.paidInCard = value;
			},
			loadOrderItems: flow(function* (
				data: { orderId: number; itemId?: number; count?: number },
				request: 'getOrderItems' | 'changeOrderItem' | 'cancelCorrection' | 'saveCorrection' | 'cancelOrder'
			) {
				try {
					root.setLoading(true);
					const res = yield OrderService[request](data);
					self.orderItems = res.data || null;
					self.loadPayment();
				} finally {
					root.setLoading(false);
				}
			}),
			setConfirmModal(message: string | null) {
				self.confirmModal = message;
			},
			createIncident(props?: Partial<CreateIncidentModel>) {
				self.createIncidentModel = CreateIncident.create({ store: root.postOffice, ...props });
			},
			cancelIncident() {
				self.createIncidentModel = null;
			},
		};
	})
	.views((self) => ({
		get isEmpty() {
			return !self.orders.length && !self.postals.length;
		},
		get issues() {
			return [...self.postals.filter(({ type }) => [1, 2].includes(type)), ...self.orders];
		},
		get receptions() {
			return self.postals.filter(({ type }) => [0].includes(type));
		},
		getPostal(postalId: number) {
			return self.postals.find(({ id }) => id === postalId);
		},
		getOrder(orderId: number) {
			return self.orders.find(({ id }) => id === orderId);
		},
		get inventoryPostals() {
			return self.postals.filter(
				({ id, additional_services: { is_inventory } }) => is_inventory && !self.confirmedInventory.has(String(id))
			);
		},
		get confirmEnabled() {
			if (this.hasOrders) return this.ordersPaid;
			if (this.hasRequistions) return !(this.hasRequistionsProcess || !this.hasPayment);
			return this.hasOnlyPostalsConfirm || this.hasRequiredConfirm;
		},
		get ordersPaid() {
			return this.paidOnline || this.change >= 0;
		},
		get confirmText() {
			if (this.hasOrders)
				return this.paidOnline ? 'Подтвердить выдачу заказа Emall' : 'Подтвердить оплату заказа Emall';

			return self.payment?.postal.final_sum && self.payment.postal.final_sum < 0
				? 'Подтвердить выдачу средств'
				: this.hasOnlyPostalsConfirm
				? 'Подтвердить выдачу ПО'
				: 'Подтвердить оплату';
		},
		get hasOrders() {
			return self.orders.some(({ status_id }) => status_id !== 90);
		},

		get hasPaymentPostals() {
			return self.postals.some(({ type }) => type === 0 || type === 2);
		},

		get hasPostals() {
			return self.postals.some(({ type }) => type === 0 || type === 1);
		},

		get finalSum() {
			if (this.hasOrders) return self.payment?.order.final_sum || 0;
			return self.payment?.postal.final_sum || 0;
		},
		get paymentSum() {
			if (this.hasOrders) return self.payment?.order.payment_sum || 0;
			return self.payment?.postal.payment_sum || 0;
		},
		get issueSum() {
			return self.payment?.postal.issue_sum || 0;
		},
		get certificateSum() {
			return self.payment?.order.certificate_sum || 0;
		},
		get paidOnline() {
			return this.hasOrders && self.payment?.order.final_sum === 0;
		},
		get change() {
			return Math.round(((self.paidInCard ?? 0) + (self.paidInCash ?? 0) - this.finalSum) * 100) / 100;
		},
		get showConfirmButton() {
			return this.hasOrders || this.hasPaymentPostals || this.hasRequiredConfirm || this.hasOnlyPostalsConfirm;
		},
		get hasRequiredConfirm() {
			return (
				self.postals.some(
					({ additional_services: { is_completeness }, isCompletenessConfirmed, type }) =>
						is_completeness && isCompletenessConfirmed === null && [1, 2].includes(type)
				) || !!(self.payment?.postal.final_sum && self.payment.postal.final_sum < 0)
			);
		},
		get hasRequistions() {
			return self.postals.some(({ type }) => type === 0);
		},
		get hasOnlyPostalsConfirm() {
			return (
				self.postals.some(({ type }) => type === 1) &&
				(self.payment?.postal.final_sum === 0 || (self.paidInCard ?? 0) + (self.paidInCash ?? 0) - this.finalSum >= 0)
			);
		},
		get hasRequistionsProcess() {
			return self.postals.some(({ type, market_status }) => type === 0 && market_status === 0);
		},
		get hasPayment() {
			return (
				(self.payment?.postal.final_sum && self.payment.postal.final_sum < 0) ||
				Math.round(((self.paidInCard ?? 0) + (self.paidInCash ?? 0) - this.finalSum) * 100) / 100 >= 0
			);
		},
		get newReceptions() {
			return self.postals.filter(({ type, market_status: status }) => type === 0 && status === 0);
		},
	}));

export default Visit;

export type VisitModel = Instance<typeof Visit>;
