import ShiftService from '@services/shift';
import { TaskService } from '@services/task';
import UserService from '@services/user';
import { applySnapshot, flow, types } from 'mobx-state-tree';
import { SnapshotIn } from 'mobx-state-tree/dist/internal';
import React, { FC, PropsWithChildren } from 'react';

import { CompensationsModel } from './compensations';
import IndentificationModel from './identification';
import { KassaModel } from './kassa';
import Print from './print';
import Shift from './shift';
import { TasksModel } from './tasks';
import UserModel, { User } from './user';
import Visits from './visits';

const { maybeNull, number, boolean, optional } = types;

export const RootStore = types
	.model({
		visits: Visits,
		indentification: IndentificationModel,
		tasks: maybeNull(TasksModel),
		compensations: CompensationsModel,
		kassa: KassaModel,
		user: maybeNull(UserModel),
		shift: maybeNull(Shift),
		postOffice: maybeNull(number),
		dataLoaded: false,
		print: Print,
		loadModal: optional(boolean, false),
		loading: false,
		loadingForce: false,
		errorMessage: '',
		isPaymentError: false,
	})
	.actions((self) => ({
		setUser(user: User) {
			self.user = user;
		},
		logout: flow(function* () {
			yield UserService.logout();
			self.user = null;
			localStorage.removeItem('user');
			localStorage.removeItem('token');
		}),
		openShift: flow(function* () {
			if (self.postOffice) {
				const shift = yield ShiftService.openShift(self.postOffice);
				self.shift = shift.data;
			}
		}),
		checkShift: flow(function* () {
			try {
				const shift = yield ShiftService.getShift();
				self.shift = shift.data;
				self.dataLoaded = true;
			} catch (e) {
				self.dataLoaded = true;
				console.error(e);
			}
		}),
		closeShift: flow(function* () {
			if (self.shift) {
				yield ShiftService.closeShift(self.shift.shift_id);
				self.shift = null;
				applySnapshot(self.visits.visitMap, { 1: {} });
			}
		}),
		openBreak: flow(function* () {
			if (self.shift && self.shift.status === 0) {
				const shift = yield ShiftService.openBreak(self.shift.shift_id);
				self.shift = shift.data;
			}
		}),
		closeBreak: flow(function* () {
			if (self.shift) {
				const shift = yield ShiftService.closeBreak(self.shift.shift_id, self.shift.break_id as number);
				self.shift = shift.data;
			}
		}),
		setPostOffice(officeId: number) {
			self.postOffice = officeId;
			localStorage.setItem('postOffice', String(officeId));
		},
		loadTask: flow(function* (noLoading?: boolean) {
			try {
				!noLoading && (self.loading = true);
				const task = yield TaskService.getTasks();
				self.tasks = task.data;
				!noLoading && (self.loading = false);
			} catch (e) {
				!noLoading && (self.loading = false);
			}
		}),
		setLoading(value: boolean) {
			self.loading = value;
		},
		setLoadingForce(value: boolean) {
			self.loadingForce = value;
		},
		setErrorMessage(value: string) {
			self.errorMessage = value;
		},
		setPaymentError(value: boolean) {
			self.isPaymentError = value;
		},
	}));

const restorePostoffice = (): number | null => {
	const officeId = localStorage.getItem('postOffice');
	if (officeId && !Number.isNaN(officeId)) {
		return Number(officeId);
	} else return null;
};

const restorePrinters = (): SelectedPrinters => {
	const printers = localStorage.getItem('printers');
	if (printers) return { printers: JSON.parse(printers), tmpPrinters: {} };
	else return { printers: {}, tmpPrinters: {} };
};

const rootStore = RootStore.create({
	visits: { visitMap: { 1: {} } },
	postOffice: restorePostoffice(),
	print: restorePrinters(),
	compensations: { list: null },
	kassa: { list: null },
	indentification: {
		user: { address: '', countAttempts: 3, fullName: '', id: null, isVerified: null, phoneNumber: '', valueCode: 0 },
	},
});

export const StoreContext = React.createContext(rootStore);

const StoreProvider: FC<PropsWithChildren> = ({ children }) => (
	<StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>
);

export default StoreProvider;

export type SelectedPrinters = SnapshotIn<typeof RootStore>['print'];
