import { api } from '@http/index';
import { Postal } from '@models/index';
import { PostalTrackingModel } from '@models/postal-tracking';
import { IncidentItem } from '@store/visits/incidents';
import { PostalCalculate } from '@store/visits/postal';
import { AxiosResponse, GenericAbortSignal } from 'axios';

interface ComplaintType {
	id: number;
	postal_complaint_type_description: string;
	postal_complaint_type_id: number;
	type_id: number;
}

interface ComplaintSaveType {
	store_id: number | null;
	info: string;
	postal_complaint_type_id: number | null;
	postal_item_number: string | null;
	direction: number | null;
	postal_weight: number | null;
}

interface ComplaintEditType {
	info: string;
	postal_complaint_id: number | null;
	postal_weight: number | null;
}

const getPostalInfo = (id: number): Promise<AxiosResponse<Postal>> => api.get<Postal>(`postal/${id}`);

const postalSave = (id: number, data: any): Promise<AxiosResponse<void>> => api.post(`postal/${id}/accept`, data);

const getPostalTracking = (id: number): Promise<AxiosResponse<PostalTrackingModel[]>> =>
	api.get(`postal/${id}/tracking`);

const putPostalPayment = (id: number): Promise<AxiosResponse<PostalTrackingModel[]>> => api.put(`postal/${id}/payment`);

const getPostalCalc = (id: number, params: any, signal: GenericAbortSignal): Promise<AxiosResponse<PostalCalculate>> =>
	api.get(`postal/${id}/payment/calculate`, { params, signal });

const packing = (id: number, data: any): Promise<AxiosResponse<void>> => api.post(`postal/${id}/packing`, data);

const postalCreate = (data: {
	delivery_type: number;
	weight: number;
	store_id_start: number;
	store_id_finish: number | null;
	receiver_market_address_id: number;
	receiver_name: string;
	receiver_surname: string;
	receiver_patronymic_name: string;
	receiver_phone_number: string;
	sender_id: number;
}): Promise<AxiosResponse<Postal>> => api.put<Postal>('/postal/create', data);

const getIncidents = (id: number): Promise<AxiosResponse<IncidentItem>> => api.get(`postal/complaint/${id}`);

const getPostalComplaintType = (): Promise<AxiosResponse<ComplaintType[]>> => api.get(`postal-complaint-type`);

const postalComplaintSave = (data: ComplaintSaveType): Promise<AxiosResponse<void>> =>
	api.post(`postal/complaint/`, data);

const postalComplaintEdit = (id: number, data: ComplaintEditType): Promise<AxiosResponse<void>> =>
	api.put(`postal/complaint/${id}`, data);

export const PostalService = {
	getPostalInfo,
	getPostalTracking,
	getPostalCalc,
	postalSave,
	postalCreate,
	getIncidents,
	getPostalComplaintType,
	postalComplaintSave,
	postalComplaintEdit,
	packing,
	putPostalPayment,
};
