import Button from '@components/buttons/button';
import { useStore } from '@hooks/useStore';
import { Form, Input } from 'antd';
import { ChangeEvent, FC, useState } from 'react';

import styles from './index.module.scss';

const ClientSearch: FC = () => {
	const { indentification } = useStore();
	const [value, setValue] = useState<string>('');

	const handleSearch = (): void => {
		indentification.searchUser(value);
	};

	const handleInput = (e: ChangeEvent<HTMLInputElement>): void => {
		setValue(e.target.value);
	};

	return (
		<Form>
			<div className={styles.wrapper}>
				<Input onChange={handleInput} value={value} className={styles.input} placeholder="Поиск" />
				<Button htmlType="submit" onClick={handleSearch}>
					Поиск
				</Button>
			</div>
		</Form>
	);
};

export default ClientSearch;
