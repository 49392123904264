import { types } from 'mobx-state-tree';

export interface IUser {
	id: number;
	fullName: string;
	isVerified: boolean;
	phoneNumber: string;
	address: string;
	code?: number;
}

const mockData: IUser[] = [
	{
		id: 1,
		fullName: 'Иван Иванов',
		isVerified: false,
		phoneNumber: '375331111111',
		address: 'г. Брест, ул. Большая Садовая, д. 1',
		code: 111,
	},
	{
		id: 2,
		fullName: 'Ольга Петрова',
		isVerified: false,
		phoneNumber: '375291234567',
		address: 'г. Брест, ул. Невский проспект, д. 1',
	},
	{
		id: 3,
		fullName: 'Алексей Смирнов',
		isVerified: true,
		phoneNumber: '375445478963',
		address: 'г. Могилев, ул. Ленина, д. 1, ',
	},
	{
		id: 4,
		fullName: 'Анна Кузнецова',
		isVerified: false,
		phoneNumber: '375296984852',
		address: 'г. Климовичи, ул. Максима Горького, д. 1',
	},
	{
		id: 5,
		fullName: 'Петр Васильев',
		isVerified: true,
		phoneNumber: '375445002578',
		address: 'г. Гомель, ул. Лермонтова, д. 1',
	},
	{
		id: 6,
		fullName: 'Мария Белова',
		isVerified: false,
		phoneNumber: '375336597812',
		address: 'г. Гродно, ул. Пушкинская, д. 1',
	},
	{
		id: 7,
		fullName: 'Сергей Николаев',
		isVerified: true,
		phoneNumber: '375295457896',
		address: 'г. Витебск, ул. Чехова, д. 1',
	},
	{
		id: 8,
		fullName: 'Елена Козлова',
		isVerified: false,
		phoneNumber: '375256457821',
		address: 'г. Орша, ул. Достоевского, д. 1',
	},
	{
		id: 9,
		fullName: 'Владимир Морозов',
		isVerified: true,
		phoneNumber: '375292105489',
		address: 'г. Кричев, ул. Толстого, д. 1',
	},
	{
		id: 10,
		fullName: 'Ирина Егорова',
		isVerified: false,
		phoneNumber: '375447891265',
		address: 'г. Костюковичи, ул. Гоголя, д. 1',
	},
];

const { maybeNull, number, boolean, string, model, optional, union, literal } = types;

const Indentification = model('Indentification', {
	id: maybeNull(number),
	fullName: string,
	isVerified: maybeNull(boolean),
	phoneNumber: string,
	address: string,
	countAttempts: 1 | 2 | 3,
	code: 333,
	valueCode: number,
});

const IndentificationModel = model('Indentification', {
	user: Indentification,
	isOpenModal: optional(boolean, false),
	isOpenModalSuces: optional(boolean, false),
	isCheckedDocuments: optional(boolean, false),
	step: optional(union(literal(0), literal(1)), 0),
	valueCode: maybeNull(number),
}).actions((self) => ({
	searchUser(phone: string) {
		const number = phone.indexOf('+') !== -1 ? phone.trim().slice(1, phone.length) : phone;
		const item: IUser | undefined = mockData.find((user: IUser) => user.phoneNumber === number);

		if (item?.id) {
			self.user.id = item.id;
			self.user.fullName = item.fullName;
			self.user.isVerified = item.isVerified;
			self.user.phoneNumber = item.phoneNumber;
			self.user.address = item.address;
			self.user.countAttempts = 3;
			self.step = 0;
		} else {
			self.user.id = null;
		}
	},
	setIsOpenModal(value: boolean) {
		if (value === false) {
			self.isOpenModal = value;
			self.step = 0;
			self.isCheckedDocuments = false;
			self.valueCode = null;
		} else {
			self.isOpenModal = value;
		}
	},
	setIsOpenModalSuccess(value: boolean) {
		self.isOpenModalSuces = value;
	},
	setValueCode(value: number | null) {
		self.valueCode = value;
	},
	setCheckedDoc() {
		self.isCheckedDocuments = !self.isCheckedDocuments;
	},
	setStep() {
		if (self.step === 1 && self.valueCode === 333) {
			this.setIsOpenModal(false);
			self.user.isVerified = true;
			self.isOpenModalSuces = true;
		} else {
			if (self.isCheckedDocuments) {
				self.step = 1;
			}
		}
	},
	setCountAttempts() {
		if (self.user.countAttempts !== 0) {
			self.user.countAttempts -= 1;
		}
	},
}));

export default IndentificationModel;
