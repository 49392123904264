import { api } from '@http/index';
import { Order, Postal } from '@models/index';
import { AxiosResponse, GenericAbortSignal } from 'axios';

interface SearchResp {
	postalItems: {
		id: number;
		number: string;
		type: 0 | 1 | 2;
		receiver_fio: string;
		receiver_phone: string;
		sender_fio: string;
		sender_phone: string;
		weight: string;
	}[];
	orders: {
		id: number;
		market_id: number;
		receiver_fio: string;
		receiver_phone: string;
	}[];
}

interface FindAdditionalResp {
	postalItems: Postal[];
	orders: Order[];
}

const findPostal = (search: string, signal?: GenericAbortSignal): Promise<AxiosResponse<SearchResp>> =>
	api.get<SearchResp>('search', { params: { search }, signal });

const findAdditional = (id: number, type: string): Promise<AxiosResponse<FindAdditionalResp>> =>
	api.get(`search/additional/${id}`, { params: { type } });

const SearchService = { findPostal, findAdditional };

export default SearchService;
