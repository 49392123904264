import Tabs from '@components/tabs';
import { useStore } from '@hooks/useStore';
import { Input } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './index.module.scss';

const TasksHeader: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const path = location.pathname.split('/')[2];
	const store = useStore();

	return (
		<>
			<h4 style={{ marginBottom: 16, lineHeight: '28px' }}>Задания</h4>
			<Tabs
				items={[
					{
						key: 'emall',
						label: <BadgedTab name="Emall" amount={store.tasks?.orderAmount || 0} />,
					},
					{ key: 'mail', label: <BadgedTab name="Почта" amount={store.tasks?.postalAmount || 0} /> },
				]}
				activeKey={path}
				onChange={(key) => navigate(key)}
			/>
			<div
				style={{
					overflow: 'hidden',
				}}
			></div>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 24 }}>
				<Input
					style={{ width: 596 }}
					placeholder="Поиск"
					value={store.tasks?.search || undefined}
					onChange={(e) => {
						store.tasks?.setSearch(e.target.value);
					}}
				/>
			</div>
		</>
	);
};

export default observer(TasksHeader);

const BadgedTab: FC<{ name: string; amount: number }> = ({ name, amount }) => {
	return (
		<div className={styles.tab}>
			<span>{name}</span>
			<span className={styles.tab_badge}>{amount}</span>
		</div>
	);
};
