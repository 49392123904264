import Button from '@components/buttons/button';
import Input from '@components/input';
import InputNumber from '@components/inputNumber';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { addZero } from 'utils/addZero';

import styles from './index.module.scss';

const InspectNumberPhone: FC = () => {
	const { indentification } = useStore();
	const [time, setTime] = useState<number | null>(null);
	const [textButton, setTextButton] = useState('Отправить код');
	const [textAttempts, setTextAttempts] = useState('');
	const [isDisabled, setIsDisabled] = useState(false);
	const [isShow, setIsShow] = useState(false);

	useEffect(() => {
		const timer = setInterval(() => {
			if (time !== null && time >= 0) {
				if (time === 0) {
					setTextButton(`Отправить еще код`);
					clearInterval(timer);
				} else {
					setTextButton(`Отправить еще код 00:${addZero(time)}`);
				}
				setTime(time - 1);
			}
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, [time]);

	const getCode = (): void => {
		if (indentification.user.countAttempts === 3) {
			setIsShow(true);
		}
		indentification.setCountAttempts();
		if (indentification.user.countAttempts === 0) {
			setIsDisabled(true);
			setTextAttempts('Попытки исчерпаны, новые появятся завтра');
		} else {
			setTime(30);
			setTextAttempts(`Осталось попыток: ${indentification.user.countAttempts}`);
		}
	};
	return (
		<div className={styles.wrapper}>
			<div>
				<p>{indentification.user.fullName}</p>
				<p>{indentification.user.address}</p>
			</div>
			<div className={styles.wrapper__code}>
				<Input
					disabled
					className={styles.phone}
					isPhone
					placeholder="Телефон"
					value={indentification.user.phoneNumber}
				/>
				<div className={styles.wrapper__inspect}>
					<Button disabled={isDisabled || (time !== null && time >= 0)} onClick={getCode}>
						{textButton}
					</Button>
					<p className={styles.status}>{indentification.user.countAttempts < 3 && textAttempts}</p>
				</div>
				{isShow && (
					<InputNumber
						className={styles.code}
						placeholder="Код"
						value={indentification.valueCode}
						setValue={indentification.setValueCode}
						errorMessage={String(indentification.valueCode).length !== 3 ? 'Неверный код' : null}
					/>
				)}
			</div>
		</div>
	);
};

export default observer(InspectNumberPhone);
