import Badge from '@components/badge';
import Button from '@components/buttons/button';
import Loading from '@components/loading';
import Popover from '@components/popover';
import Content from '@components/popover/components/content';
import colors from '@config/colors';
import { useMessage } from '@hooks/useMessage';
import { useStore } from '@hooks/useStore';
import StoreService from '@services/store';
import { ConfigProvider, Layout, Menu, MenuProps } from 'antd';
import { Header } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import cn from 'classnames';
import { Observer, observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import Icon from '../../images';
import ModalBreak from './components/modal-break';
import ModalCloseShift from './components/modal-close-shift';
import ModalExit from './components/modal-exit';
import OpenShift from './components/open-shift';
import SelectPrinterModal from './components/select-printer-modal';
import styles from './index.module.scss';

let intervalId: any;

type TriggerProps = {
	collapsed: boolean;
};

const Trigger: FC<TriggerProps> = ({ collapsed }) => (
	<ConfigProvider
		theme={{
			token: {
				colorIcon: '#000',
			},
		}}
	>
		<div className={styles.trigger}>
			<Icon
				imageName="LeftArrow"
				className={styles.trigger_icon}
				color={colors.grey1}
				rotate={collapsed ? 180 : undefined}
			/>
		</div>
	</ConfigProvider>
);

const QRContent: FC = () => {
	const store = useStore();
	const [qr, setQr] = useState<ArrayBuffer | null>(null);
	useEffect(() => {
		StoreService.getQrCode().then((value) => setQr(value.data));
	}, []);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			{qr && (
				<>
					<Document file={qr}>
						<Page pageNumber={1} width={204} height={204} scale={1} loading="Загрузка страницы" />
					</Document>
					<Button style={{ width: 190 }} onClick={() => store.print.printTask({ type: 'qr' })}>
						Печать QR-кода
					</Button>
				</>
			)}
		</div>
	);
};

const Main: FC = () => {
	const [collapsed, setCollapsed] = useState(false);
	const [logoutConfirm, setLogoutConfirm] = useState(false);
	const [openCloseShift, setOpenCloseShift] = useState(false);

	const [open, contextHolder] = useMessage({ duration: 2 });
	const location = useLocation();
	const navigate = useNavigate();
	const store = useStore();

	const selectMenu: MenuProps['onSelect'] = ({ key }): void => {
		navigate(key);
	};

	const logout = (): void => {
		store.logout();
	};

	const confirmLogout = (): void => {
		setLogoutConfirm(true);
	};

	if (location.pathname === '/') {
		navigate('/visits');
	}

	useEffect(() => {
		clearInterval(intervalId);
		if (!store.shift || location.pathname.startsWith('/tasks')) return;

		store.loadTask(true);
		intervalId = setInterval(() => store.loadTask(true), 5 * 60 * 1000);
		return () => clearInterval(intervalId);
	}, [store.shift, location]);

	useEffect(() => {
		store.errorMessage && open(store.errorMessage);
		//eslint-disable-next-line
	}, [store.errorMessage]);

	useEffect(() => {
		if (store.shift?.status === 0) {
			let time = 0;
			const activityHandler = (): void => {
				time = 0;
			};

			addEventListener('mousemove', activityHandler);
			addEventListener('click', activityHandler);
			addEventListener('keydown', activityHandler);

			const interval = setInterval(() => {
				time += 1;
				if (time >= 10) {
					store.openBreak();
				}
			}, 1000 * 60);

			return () => {
				removeEventListener('mousemove', activityHandler);
				removeEventListener('click', activityHandler);
				removeEventListener('keydown', activityHandler);
				clearInterval(interval);
			};
		} else if (!store.shift) {
			store.checkShift();
		}
	}, [store.shift]);

	const leftMenuItems = [
		{
			icon: <Icon className={styles.icon} imageName="People" />,
			label: 'Визиты',
			key: 'visits',
			disabled: !store.shift,
		},
		{
			icon: <Icon className={styles.icon} imageName="PlaylistAddCheck" />,
			label: 'Задания',
			key: 'tasks',
			disabled: !store.shift,
			count: store.tasks?.tasksAmount,
		},
		{
			icon: <Icon className={styles.icon} imageName="ReceiptLong" />,
			label: 'Касса',
			key: 'kassa',
			disabled: !store.shift,
		},
		{
			icon: <Icon className={styles.icon} imageName="Compensations" />,
			label: 'Возмещения',
			key: 'compensations',
			disabled: !store.shift,
		},
		{
			icon: <Icon className={styles.icon} imageName="FileCopy" />,
			label: 'Отчеты',
			key: 'reports',
			disabled: !store.shift,
		},
		{
			icon: <Icon className={styles.icon} imageName="IdentificationIconNav" />,
			label: 'Идентификация',
			key: 'identification',
			disabled: !store.shift,
		},
		// {
		// 	icon: <Icon className={styles.icon} imageName="LocalShipping" />,
		// 	label: 'Логистика',
		// 	key: 'logistics',
		// 	disabled: !store.shift,
		// },
		// { icon: <Icon className={styles.icon} imageName="FileCopy" />, label: 'Отчеты', key: 'reports' },
		// { icon: <Icon className={styles.icon} imageName="LibraryBooks" />, label: 'Инвентаризация', key: 'inventory' },
	].map((item) => ({
		...item,
		className: styles.menu_item,
		label:
			'count' in item ? (
				<Observer>
					{() => (
						<Badge count={item.count} size="small" offset={[6, 0]} color="red">
							{item.label}
						</Badge>
					)}
				</Observer>
			) : (
				item.label
			),
	}));

	if (!store.dataLoaded) {
		return null;
	}

	return (
		<>
			<Layout className={styles.main}>
				<Header className={styles.header}>
					<Popover
						content={
							<>
								<Content
									items={
										!store.shift
											? [
													{ text: 'Открыть смену', iconName: 'Sun', onClick: store.openShift },
													{ text: 'Выйти', onClick: confirmLogout },
													{
														text: 'Настройки принтера',
														iconName: 'Settings',
														onClick: store.print.openPrinterSettings,
													},
											  ]
											: [
													{ text: 'Перерыв', iconName: 'Pause', onClick: store.openBreak },
													{ text: 'Закрыть смену', iconName: 'Moon', onClick: () => setOpenCloseShift(true) },
													{
														text: 'Настройки принтера',
														iconName: 'Settings',
														onClick: store.print.openPrinterSettings,
													},
											  ]
									}
								/>
								<QRContent />
							</>
						}
					>
						<span
							className={cn(styles.account, {
								[styles.account_break]: store.shift?.status === 1,
								[styles.account_closed]: !store.shift,
							})}
						>
							{store.user?.name}
						</span>
					</Popover>
				</Header>
				<Layout>
					<Sider
						className={styles.sider}
						theme="light"
						collapsible
						width={207}
						collapsedWidth={53}
						trigger={<Trigger collapsed={collapsed} />}
						onCollapse={(collapsed) => setCollapsed(collapsed)}
					>
						<ConfigProvider
							theme={{
								token: { borderRadius: 0 },
								components: {
									Menu: { colorItemTextSelected: colors.blue },
								},
							}}
						>
							<Menu
								items={leftMenuItems}
								mode="inline"
								selectedKeys={[location.pathname.split('/')[1]]}
								onSelect={selectMenu}
								className={styles.menu}
							/>
						</ConfigProvider>
					</Sider>
					{store?.shift ? <Outlet /> : <OpenShift />}
				</Layout>
			</Layout>
			<ModalExit onConfirm={logout} open={logoutConfirm} onCancel={() => setLogoutConfirm(false)} />
			<ModalBreak open={store.shift?.status === 1} />
			<ModalCloseShift start="" end="" open={openCloseShift} onClose={() => setOpenCloseShift(false)} />
			<SelectPrinterModal />
			<Loading show={store.loadingForce || store.loading} />
			{contextHolder}
		</>
	);
};

export default observer(Main);
