import Button from '@components/buttons/button';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { Steps } from 'antd';
import { observer } from 'mobx-react-lite';

import styles from './index.module.scss';
import InspectsDataClient from './inspects-data-client';
import InspectNumberPhone from './inspects-number-phone';

// export interface IModalIndentifyProps {}

const ModalIndentify = () => {
	const { indentification } = useStore();
	const setStep = () => {
		indentification.setStep();
	};
	return (
		<Modal
			centered
			title="Создание заявки"
			open={indentification.isOpenModal}
			width={720}
			destroyOnClose
			onOk={() => indentification.setIsOpenModal(false)}
			onCancel={() => indentification.setIsOpenModal(false)}
			maskClosable={false}
			footer={
				<footer className={styles.footer}>
					<Button onClick={() => indentification.setIsOpenModal(false)}>Отмена</Button>
					<Button
						disabled={!indentification.isCheckedDocuments}
						onClick={() => indentification.setStep()}
						type="primary"
					>
						Продолжить
					</Button>
				</footer>
			}
		>
			<div>
				<div className={styles.steps}>
					<Steps
						current={indentification.step}
						progressDot
						items={[{ title: 'Проверка данных клиента' }, { title: 'Проверка номера телефона' }]}
					/>
				</div>
				{indentification.step === 0 ? <InspectsDataClient /> : <InspectNumberPhone />}
			</div>
		</Modal>
	);
};
export default observer(ModalIndentify);
