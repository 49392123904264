import { FC } from 'react';
import ReactDOM from 'react-dom';

const Loading: FC<{ show?: boolean }> = ({ show }) => {
	if (show) {
		return ReactDOM.createPortal(
			<div
				style={{
					position: 'fixed',
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
					zIndex: 2000,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
				dangerouslySetInnerHTML={{
					__html: `<dotlottie-player
					src="/loading.json"
					background="transparent"
					speed="1"
					style="width: 120px; height: 120px"
					direction="1"
					mode="normal"
					loop
					autoplay
				></dotlottie-player>`,
				}}
			></div>,
			document.body
		);
	}
	return null;
};

export default Loading;
