import Input from '@components/input';
import InputNumber from '@components/inputNumber';
import Modal from '@components/modal';
import Select from '@components/select';
import TextArea from '@components/textarea';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { Checkbox, message } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import PostOfficeSelection from '../common/post-office-select';
import ComplainTypeSelection from './components/postal-complaint-type-select';
import styles from './index.module.scss';

const CreateIncident: FC = observer(() => {
	const visit = useCurrentVisit();
	const model = visit?.createIncidentModel;
	const [messageApi, contextHolder] = message.useMessage({
		top: 64,
		duration: 2,
		prefixCls: 'messages',
	});

	useEffect(() => {
		let timerId: number;

		if (visit?.createIncidentModel?.errorMessage) {
			messageApi.open({ type: 'error', content: visit?.createIncidentModel?.errorMessage, className: 'message' });
			timerId = window.setTimeout(() => {
				visit.createIncidentModel?.setError('');
			}, 2000);
		}

		return () => {
			clearTimeout(timerId);
		};
		//eslint-disable-next-line
	}, [visit?.createIncidentModel?.errorMessage]);

	return (
		<Modal
			centered
			title="Создание инцидента"
			open={!!model}
			width={720}
			destroyOnClose
			onCancel={visit?.cancelIncident}
			maskClosable={false}
			className={styles.modal}
			okText="Отправить"
			cancelText="Отмена"
			okButtonProps={{ disabled: !visit?.createIncidentModel?.isFieldsFill, onClick: visit?.createIncidentModel?.save }}
		>
			<Select
				placeholder="Наличие акта-претензии"
				required
				value={visit?.createIncidentModel?.actType}
				onChange={(value) => visit?.createIncidentModel?.setActType(Number(value))}
				options={['Без акта', 'Акт от отправителя', 'Акт от получателя'].map((name, i) => ({
					value: i,
					label: name,
				}))}
				isActive={!!visit?.createIncidentModel?.actType || visit?.createIncidentModel?.actType === 0}
				disabled={visit?.createIncidentModel?.isEdit}
			/>
			<Input
				placeholder="Номер ПО"
				value={
					visit?.createIncidentModel?.number ||
					(visit?.icidents?.list ? visit.icidents.list[0].postal_item_number : '') ||
					''
				}
				onChange={({ target: { value } }) => {
					if (value.length === 1 && value[1] !== 'B') return visit?.createIncidentModel?.setNumber(`BY0800${value}`);
					visit?.createIncidentModel?.setNumber(value);
				}}
				disabled={visit?.createIncidentModel?.isEdit || !visit?.createIncidentModel?.isNew}
			/>
			<PostOfficeSelection
				placeholder="ОПС"
				onChange={(val) => visit?.createIncidentModel?.setStore(Number(val))}
				value={visit?.createIncidentModel?.store}
				required
				disabled={visit?.createIncidentModel?.isEdit}
				wrapperStyle={{ flexBasis: '100%' }}
			/>
			<ComplainTypeSelection
				placeholder="Причина"
				onChange={(val) => visit?.createIncidentModel?.setReason(Number(val))}
				value={visit?.createIncidentModel?.reason}
				isNew={visit?.createIncidentModel?.isNew}
				required
				style={{ width: 328 }}
				disabled={visit?.createIncidentModel?.isEdit}
			/>
			<InputNumber
				className={styles.input__weight}
				placeholder="Фактический вес, кг"
				value={visit?.createIncidentModel?.weight}
				setValue={visit?.createIncidentModel?.setWeight}
				max={999.99}
				maxLength={6}
				disabled={visit?.createIncidentModel?.isEdit && !(visit.createIncidentModel.status === 0)}
			/>
			<TextArea
				placeholder="Комментарий"
				value={visit?.createIncidentModel?.comment || ''}
				onChange={(evt) => visit?.createIncidentModel?.setComment(evt.target.value)}
			/>
			<Checkbox
				className={styles.checkbox}
				onChange={visit?.createIncidentModel?.toggleActOnOpen}
				checked={!!visit?.createIncidentModel?.isActOnOpen}
			>
				Печатать акт на вскрытие
			</Checkbox>
			<Checkbox
				onChange={visit?.createIncidentModel?.toggleActOnVisual}
				checked={!!visit?.createIncidentModel?.isActOnVisual}
				className={styles.checkbox}
			>
				Печатать акт на внешний осмотр
			</Checkbox>
			{!!visit?.createIncidentModel?.actType && (
				<Checkbox
					onChange={visit?.createIncidentModel?.toggleActClaim}
					checked={!!visit?.createIncidentModel?.isActClaim}
					className={styles.checkbox}
				>
					Печатать акт-претензию
				</Checkbox>
			)}
			{contextHolder}
		</Modal>
	);
});

export default CreateIncident;
