import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import NoTasks from '../no-tasks';
import EmallTasksTable from './components/table';

const EmallTasks: FC = () => {
	const store = useStore();
	useEffect(() => {
		if (store.tasks?.search?.length) {
			store.tasks.setSearch('');
		}
	}, []);
	return (store.tasks?.orderAmount && <EmallTasksTable />) || <NoTasks />;
};

export default observer(EmallTasks);
