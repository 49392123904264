import { AddressService } from '@services/address';
import { ClientService } from '@services/client';
import { PostalService } from '@services/postal';
import { flow, getParent, getRoot, types } from 'mobx-state-tree';

import { RootStore } from '..';
import Visit from './visit';

const { model, union, literal, optional, number, string, boolean, maybeNull, array } = types;

export const City = model('city', {
	value: number,
	label: string,
});

const Address = model('address', {
	city: maybeNull(number),
	street: maybeNull(number),
	house: '',
	building: '',
	flat: '',
	streetList: array(
		model({
			id: number,
			market_id: number,
			name: string,
		})
	),
}).actions((self) => ({
	setCity(value: number | null) {
		self.city = value;
		this.loadStreets(value);
	},
	setStreet(value: number | null) {
		self.street = value;
	},
	setHouse(value: string) {
		self.house = value;
	},
	setBuilding(value: string) {
		self.building = value;
	},
	setFlat(value: string) {
		self.flat = value;
	},
	loadStreets: flow(function* (cityId) {
		if (!cityId) return;
		const res = yield AddressService.streets(cityId);
		self.streetList = res.data.streets;
	}),
}));

const Person = model('personData', {
	phone_number: optional(string, ' '),
	surname: '',
	name: '',
	patronymic_name: '',
	staticCity: maybeNull(City),
}).actions((self) => ({
	setPhone(phone: string) {
		self.phone_number = phone;
	},
	setSurname(value: string) {
		self.surname = value;
	},
	setName(value: string) {
		self.name = value;
	},
	setPatronymic(value: string) {
		self.patronymic_name = value;
	},
}));

const CreateOrder = model('createOrder', {
	step: optional(union(literal(0), literal(1), literal(2)), 0),
	receiver: optional(Person, {}),
	receiverAddress: optional(Address, {}),
	requistionsData: optional(
		model('requistionsData', {
			weight: maybeNull(number),
			delivery_type: optional(union(literal(1), literal(2)), 1),
			store_id_start: maybeNull(number),
			store_id_finish: maybeNull(number),
			weightError: maybeNull(string),
			isLargeWeightLimitSender: optional(boolean, false),
			isLargeWeightLimitReceiver: optional(boolean, false),
		}).actions((self) => ({
			setWeight(weight: number | null) {
				self.weight = weight;
				self.weightError =
					(weight &&
						(weight > 50
							? 'Неподходящий вес'
							: weight > 30 && !self.isLargeWeightLimitSender
							? 'Неподходящий вес для выбранного ОПС отправки'
							: weight > 30 && !self.isLargeWeightLimitReceiver && self.store_id_finish
							? 'Неподходящий вес для выбранного ОПС выдачи'
							: null)) ||
					null;
			},
			setDeliveryType(type: 1 | 2) {
				self.delivery_type = type;
			},
			setStoreIdStart(id: number) {
				self.store_id_start = id;
			},
			setStoreIdFinish(id: number) {
				self.store_id_finish = id;
			},
			setIsLargeWeightLimitSender(val: boolean) {
				self.isLargeWeightLimitSender = val;
			},
			setIsLargeWeightLimitReceiver(val: boolean) {
				self.isLargeWeightLimitReceiver = val;
			},
		})),
		{}
	),
	sender: optional(Person, {}),
	senderAddress: optional(Address, {}),
	clientId: maybeNull(string),
	errorMessage: optional(string, ''),
	isDisabledSenderFilds: optional(boolean, false),
})
	.actions((self) => ({
		setStep(step: 0 | 1 | 2) {
			self.step = step;
		},
		getInfoByPhone: flow(function* (phoneNumber: string) {
			self.sender.surname = '';
			self.sender.name = '';
			self.sender.patronymic_name = '';
			self.senderAddress.city = null;
			self.senderAddress.street = null;
			self.senderAddress.house = '';
			self.senderAddress.building = '';
			self.senderAddress.flat = '';
			self.isDisabledSenderFilds = false;
			self.sender.staticCity = null;
			self.senderAddress.city = null;

			const phone = phoneNumber.replace(/[^0-9]/g, '');
			if (phone.length < 7) return;

			const store = getRoot<typeof RootStore>(self);
			store.setLoading(true);

			try {
				const { data: client } = yield ClientService.create({
					phone_number: phone,
				});
				self.sender.surname = client.surname;
				self.sender.name = client.name;
				self.sender.patronymic_name = client.patronymic_name;
				self.sender.staticCity = { value: client.city_id, label: client.city_name_full };
				self.senderAddress.city = client.city_id;
				self.senderAddress.streetList = [
					{ id: client.street_id, market_id: client.street_id, name: client.street_name },
				] as any;
				self.senderAddress.street = client.street_id;
				self.senderAddress.house = client.house_number_name;
				self.senderAddress.building = client.housing_name || '';
				self.senderAddress.flat = client.flat_number;
				self.isDisabledSenderFilds = true;
			} finally {
				store.setLoading(false);
			}
		}),
		finishFirstStep: flow(function* () {
			const root = getRoot<typeof RootStore>(self);
			root.setLoading(true);
			try {
				const addr = yield AddressService.create({
					street_id: self.senderAddress.street || 0,
					house_number: self.senderAddress.house,
					housing_name: self.senderAddress.building,
					flat_number: self.senderAddress.flat,
				});

				if (addr.data?.error) {
					self.errorMessage = addr.data.error;
					return;
				}

				const addressId = addr.data.flat_market_id;

				const phone = self.sender.phone_number.replace(/[^0-9]/g, '');
				const client = yield ClientService.create({
					flat_market_id: addressId,
					surname: self.sender.surname,
					name: self.sender.name,
					patronymic_name: self.sender.patronymic_name,
					phone_number: phone.length < 7 ? '' : phone,
				});
				self.clientId = client.data.client_market_id;
				self.step = 1;
			} catch (err: any) {
				self.errorMessage = err?.message || err?.error;
			} finally {
				root.setLoading(false);
			}
		}),
		finishSecondStep: flow(function* () {
			const root = getRoot<typeof RootStore>(self);
			root.setLoading(true);
			let marketAddressId = null;
			try {
				if (self.requistionsData.delivery_type === 2) {
					const addr = yield AddressService.create({
						street_id: self.receiverAddress.street || 0,
						house_number: self.receiverAddress.house,
						housing_name: self.receiverAddress.building,
						flat_number: self.receiverAddress.flat,
					});

					if (addr.data?.error) {
						self.errorMessage = addr.data.error;
						return;
					}

					marketAddressId = addr.data.flat_market_id;
				}

				const phone = self.receiver.phone_number.replace(/[^0-9]/g, '');
				const postal = yield PostalService.postalCreate({
					receiver_market_address_id: marketAddressId,
					delivery_type: Number(self.requistionsData.delivery_type),
					weight: Number(self.requistionsData.weight),
					store_id_start: Number(self.requistionsData.store_id_start),
					store_id_finish:
						self.requistionsData.delivery_type === 1 ? Number(self.requistionsData.store_id_finish) : null,
					receiver_name: self.receiver.name,
					receiver_surname: self.receiver.surname,
					receiver_patronymic_name: self.receiver.patronymic_name,
					sender_id: Number(self.clientId),
					receiver_phone_number: phone.length < 7 ? '' : phone,
				});

				if (postal.data?.error) {
					self.errorMessage = postal.data.error;
					return;
				}

				if (postal.data.id) {
					getParent<typeof Visit>(self).processPostal(postal.data.id);
					self.step = 2;
				}
			} catch (err: any) {
				self.errorMessage = err?.message || err?.error;
			} finally {
				root.setLoading(false);
			}
		}),
		onNextStep() {
			if (self.step === 0) {
				this.finishFirstStep();
			}
			if (self.step === 1) {
				this.finishSecondStep();
			}
		},
		setError(message: string) {
			self.errorMessage = message;
		},
		setDisabledSenderFields(val: boolean) {
			self.isDisabledSenderFilds = val;
		},
	}))
	.views((self) => ({
		get isFieldsFill() {
			const hasPersonInfo = (): boolean => {
				const person = self.step === 0 ? 'sender' : 'receiver';
				return !!(self[person].name && self[person].surname && self[person].patronymic_name);
			};

			const hasPersonAddressInfo = (): boolean => {
				const person = self.step === 0 ? 'senderAddress' : 'receiverAddress';
				return !!(self[person].city && self[person].street && self[person].house);
			};

			if (self.step === 0) {
				return !!(hasPersonInfo() && hasPersonAddressInfo());
			}

			if (self.step === 1) {
				return !!(
					hasPersonInfo() &&
					self.receiver.phone_number.trim() &&
					self.requistionsData.store_id_start &&
					self.requistionsData.weight &&
					(self.requistionsData.delivery_type === 2
						? hasPersonAddressInfo()
						: !!self.requistionsData.store_id_finish && !self.requistionsData.weightError)
				);
			}
		},
		get isLarge() {
			const weight = self.requistionsData.weight;
			return weight && weight > 30 && weight < 50;
		},
	}));

export default CreateOrder;
