import Button from '@components/buttons/button';
import Modal from '@components/modal';
import Tabs from '@components/tabs';
import colors from '@config/colors';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { TabsProps, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ClearVisitButton from './components/clear-visit-button';
import ClientSearch from './components/search';
import styles from './index.module.scss';

const itemAdd = {
	key: 'addVisit',
	label: (
		<div style={{ textAlign: 'right' }}>
			<Icon imageName="Plus" stroke={colors.black} fontSize={16} style={{ margin: '0 10px' }} />
		</div>
	),
};

const VisitsHeader: FC = observer(() => {
	const { visit } = useParams();
	const { visits } = useStore();
	const navigate = useNavigate();
	const tabKeys = Array.from<string>(visits.visitMap.keys());
	const visitModel = useCurrentVisit();

	const tabItems: TabsProps['items'] = tabKeys.sort().map((key) => ({
		key,
		label: (
			<div className={styles.tab}>
				<span>{`Визит ${key}`}</span>
				{tabKeys.length > 1 && (
					<Button
						className={styles.tab_button}
						onClick={(evt) => {
							evt.stopPropagation();
							visits.closeVisit(key);
						}}
					>
						<Icon imageName="Close" fontSize={12} color={'#8C8C8C'} style={{ margin: 0 }} />
					</Button>
				)}
			</div>
		),
	}));

	const selectTab = (tab: string): void => {
		if (tab !== 'addVisit') {
			navigate(tab);
		} else {
			visits.addVisit();
		}
	};

	const changeTab = (key: string): void => {
		const keys = Array.from(visits.visitMap.keys());

		if (key === 'addVisit') {
			navigate(keys[keys.length - 1]);
		}
	};

	useEffect(() => {
		if (!visit || !tabKeys.includes(visit)) navigate(tabKeys[0]);
	}, [tabKeys]);

	return (
		<>
			<div>
				<Typography.Title className={styles.title} level={4}>
					Визиты
				</Typography.Title>
				<Tabs
					className={styles.tabs}
					items={tabItems.length < 4 ? [...tabItems, itemAdd] : tabItems}
					activeKey={visit}
					onTabClick={selectTab}
					onChange={changeTab}
				/>
				<div className={styles.controls}>
					<ClientSearch />
					<Button onClick={() => visitModel?.createOrder()}>Создать заявку</Button>
					<div className={styles.clear}>
						<Button
							className={styles.button}
							icon={<Icon fontSize={16} imageName="Exclamation" />}
							onClick={() => visitModel?.createIncident({ isNew: true })}
						/>
						<ClearVisitButton />
					</div>
				</div>
			</div>
			<Modal
				title="Есть обработанные, но неоплаченные отправления"
				open={visits.isConfirmModal}
				hasLines={false}
				centered
				width={460}
				closable={false}
				okText="Закрыть"
				cancelText="Нет"
				onOk={() => visits.openConfirmModal(visit)}
				onCancel={visits.closeConfirmModal}
			/>
		</>
	);
});

export default VisitsHeader;
