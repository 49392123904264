import colors from '@config/colors';
import { ConfigProvider, InputNumber as AntInputNumber, InputNumberProps as AntInputNumberProps } from 'antd';
import { valueType } from 'antd/es/statistic/utils';
import cn from 'classnames';
import { CSSProperties, useState } from 'react';
import React from 'react';
import { numFixed } from 'utils/numFixed';

import styles from './index.module.scss';

type InputNumberProps = Omit<
	AntInputNumberProps,
	'addonAfter' | 'addonBefore' | 'allowClear' | 'bordered' | 'showCount' | 'prefix' | 'size' | 'type' | 'controls'
> & { wrapperStyle?: CSSProperties; setValue?: (value: number | null) => void; errorMessage?: string | null };

const InputNumber = React.forwardRef<HTMLInputElement, InputNumberProps>(
	({ wrapperStyle, id, placeholder, className, required, errorMessage = null, setValue, ...props }, ref) => {
		const [showPlaceholder, setShowPlaceholder] = useState(!props.value && props.value != 0);

		const onChange = (value: valueType | null): void => {
			setShowPlaceholder(!value && value != 0);
			setValue && setValue(+`${value}`);
		};

		return (
			<ConfigProvider
				theme={{
					token: {
						controlHeight: 38,
						borderRadius: 2,
						colorBgContainerDisabled: '#fff',
						colorTextDisabled: colors.grey1,
					},
				}}
			>
				<div className={cn(className, styles.wrap, { [styles.wrap__error]: errorMessage })} style={wrapperStyle}>
					<AntInputNumber
						{...props}
						className={styles.input}
						id={id}
						placeholder=" "
						bordered
						required={required}
						ref={ref}
						controls={false}
						onClick={({ currentTarget }) => {
							currentTarget.select();
							setShowPlaceholder(false);
						}}
						onChange={onChange}
						onBlur={({ target: { value } }) => {
							const num = numFixed(value.replace(',', '.'));
							setValue && setValue(num);
							setShowPlaceholder(!num && num != 0);
						}}
						parser={(value) => `${value}`.replace(',', '.')}
						status={errorMessage ? 'error' : undefined}
					/>
					{placeholder && (
						<label className={cn(styles.label, { [styles.label_placeholder]: showPlaceholder })} htmlFor={id}>
							{placeholder} {required && <span className={styles.asterisk}>*</span>}
						</label>
					)}
					{errorMessage && <span className={styles.error}>{errorMessage}</span>}
				</div>
			</ConfigProvider>
		);
	}
);

export default InputNumber;
