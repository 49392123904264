import CitySearchField from '@components/city-search';
import Input from '@components/input';
import InputNumber from '@components/inputNumber';
import Select from '@components/select';
import colors from '@config/colors';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { observer } from 'mobx-react-lite';
import { CSSProperties, FC, useEffect } from 'react';

import DeliveryTypeSelect from '../../../common/delivery-type-select';
import PostOfficeSelection from '../../../common/post-office-select';

const fieldStyle: CSSProperties = {
	width: 328,
};

const Ordering: FC = () => {
	const store = useStore();
	const visit = useCurrentVisit();

	useEffect(() => visit?.createOrderModel?.requistionsData.setStoreIdStart(Number(store.postOffice)), []);

	return (
		<>
			<div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
				<Input placeholder="Тип отправления" style={{ width: 328 }} value={'Посылка'} disabled />
				<div style={{ position: 'relative' }}>
					<InputNumber
						placeholder="Вес"
						style={{ width: 328 }}
						required
						value={visit?.createOrderModel?.requistionsData.weight}
						setValue={visit?.createOrderModel?.requistionsData.setWeight}
						max={999.99}
						maxLength={6}
						errorMessage={visit?.createOrderModel?.requistionsData.weightError}
					/>
					<Icon
						imageName="DownLoad"
						color={colors.blue}
						style={{
							position: 'absolute',
							right: 9,
							top: '50%',
							transform: 'translateY(-50%)',
							marginTop: visit?.createOrderModel?.requistionsData.weightError ? '-10px' : 0,
						}}
					/>
				</div>
				<DeliveryTypeSelect
					style={{ width: 672 }}
					value={visit?.createOrderModel?.requistionsData.delivery_type}
					onChange={(value) => visit?.createOrderModel?.requistionsData.setDeliveryType(value as 1 | 2)}
				/>
				<PostOfficeSelection
					placeholder="Отделение отправки"
					style={{ width: 672 }}
					onChange={(val) => {
						visit?.createOrderModel?.requistionsData.setStoreIdStart(Number(val));
					}}
					disabled={!!store.postOffice}
					value={store.postOffice}
					required
					getParamStore={(val) => {
						visit?.createOrderModel?.requistionsData.setIsLargeWeightLimitSender(val);
						visit?.createOrderModel?.requistionsData.setWeight(visit.createOrderModel.requistionsData.weight);
					}}
					needParamStore="is_large_weight_limit"
				/>
				{visit?.createOrderModel?.requistionsData.delivery_type !== 2 && (
					<PostOfficeSelection
						placeholder="Отделение выдачи"
						style={{ width: 672 }}
						onChange={(val) => {
							visit?.createOrderModel?.requistionsData.setStoreIdFinish(Number(val));
						}}
						required
						value={visit?.createOrderModel?.requistionsData.store_id_finish}
						getParamStore={(val) => {
							visit?.createOrderModel?.requistionsData.setIsLargeWeightLimitReceiver(val);
							visit?.createOrderModel?.requistionsData.setWeight(visit.createOrderModel.requistionsData.weight);
						}}
						needParamStore="is_large_weight_limit"
						isOnlyLarge={!!visit?.createOrderModel?.isLarge}
					/>
				)}
			</div>
			{visit?.createOrderModel?.requistionsData.delivery_type === 2 && (
				<>
					<b style={{ display: 'block', marginTop: 24, marginBottom: 9 }}>Адрес доставки</b>
					<div style={{ margin: '16px 0' }}>
						<CitySearchField
							required
							onSearch={() => {
								visit?.createOrderModel?.receiverAddress.setStreet(null);
								visit?.createOrderModel?.receiverAddress.setCity(null);
							}}
							onSelect={(val) => {
								visit?.createOrderModel?.receiverAddress.setStreet(null);
								visit?.createOrderModel?.receiverAddress.setCity(Number(val));
							}}
							placeholder="Населенный пункт"
						/>
					</div>

					<div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
						<Select
							placeholder="Улица"
							required
							style={fieldStyle}
							hideArrow
							value={visit?.createOrderModel?.receiverAddress.street}
							onChange={(val) => visit?.createOrderModel?.receiverAddress.setStreet(Number(val))}
							options={visit?.createOrderModel?.receiverAddress.streetList.map(({ id, name }) => ({
								value: id,
								label: name,
							}))}
							filterOption={(input, option) => String(option?.label).toLowerCase().includes(input)}
							showSearch
							disabled={!visit?.createOrderModel?.receiverAddress.city}
						/>
						<Input
							placeholder="Дом"
							required
							style={fieldStyle}
							value={visit?.createOrderModel?.receiverAddress.house}
							onChange={(e) => visit?.createOrderModel?.receiverAddress.setHouse(e.target.value)}
						/>
						<Input
							placeholder="Корпус"
							style={fieldStyle}
							value={visit?.createOrderModel?.receiverAddress.building}
							onChange={(e) => visit?.createOrderModel?.receiverAddress.setBuilding(e.target.value)}
						/>
						<Input
							placeholder="Квартира"
							style={fieldStyle}
							value={visit?.createOrderModel?.receiverAddress.flat}
							onChange={(e) => visit?.createOrderModel?.receiverAddress.setFlat(e.target.value)}
						/>
					</div>
				</>
			)}
			<b style={{ display: 'block', marginTop: 24, marginBottom: 9 }}>Данные получателя</b>
			<div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
				<Input
					placeholder="Телефон"
					required
					style={{ width: 328 }}
					isPhone
					value={String(visit?.createOrderModel?.receiver.phone_number)}
					onChange={(e) => visit?.createOrderModel?.receiver.setPhone(e.target.value)}
				/>
				<Input
					placeholder="Фамилия"
					required
					style={{ width: 328 }}
					value={visit?.createOrderModel?.receiver.surname || ''}
					onChange={(e) => visit?.createOrderModel?.receiver.setSurname(e.target.value)}
				/>
				<Input
					placeholder="Имя"
					required
					style={{ width: 328 }}
					value={visit?.createOrderModel?.receiver.name || ''}
					onChange={(e) => visit?.createOrderModel?.receiver.setName(e.target.value)}
				/>
				<Input
					placeholder="Отчество"
					required
					style={{ width: 328 }}
					value={visit?.createOrderModel?.receiver.patronymic_name || ''}
					onChange={(e) => visit?.createOrderModel?.receiver.setPatronymic(e.target.value)}
				/>
			</div>
		</>
	);
};

export default observer(Ordering);
